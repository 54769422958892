import React, {
    Component, Fragment
  } from 'react';
import DateTimePicker from './../../components/DateTimePicker/DateTimePicker';
import  MainSettingsController from './MainSettingsController';
import {connect} from 'react-redux';

const MainSettCtr=new MainSettingsController();

  class MainSettings extends Component {
    constructor(props){
        super(props);   
        this.state={
            mode:'fastest',
            fuelConsumption: 27.5,
            fuelCost: 1.2,
            additCosts: 0
        }

        this.handleTollOrFreeRoads=MainSettCtr.handleTollOrFreeRoads.bind(this);
        this.onChangeFuel=MainSettCtr.onChangeFuel.bind(this);
        this.checkDiffValueOfFuel=MainSettCtr.checkDiffValueOfFuel.bind(this);
        this.showSettings=MainSettCtr.showSettings.bind(this);
        this.changingTable=MainSettCtr.changingTable.bind(this);
        this.changingTable=MainSettCtr.changingTable.bind(this);this.changingFuelConsumption=MainSettCtr.changingFuelConsumption.bind(this);
        this.changingFuelCost=MainSettCtr.changingFuelCost.bind(this);
      }

    render() {
      return (  
        <Fragment> 
            <div>
                <p className='col-xs-6'><strong>  Исключение платных дорог </strong></p>
                <p className='col-xs-6'>
                    <input 
                        title='Маршрут будет построен исключая движение по платным дорогам'
                        type='checkbox' 
                        name='tollRoads' 
                        onChange={this.handleTollOrFreeRoads}/>
                    <span 
                        onClick={this.showSettings} 
                        className='glyphicon glyphicon-cog right settings-cog ' 
                        title='Полностью исключить платные дороги или частично'>
                    </span>
                </p>
            </div>
            <div className='accordeon-with-exceptions'>
                <ul>
                    <li>
                        <input type="radio" id="exception1"
                        name="exceptions" value="-3"  defaultChecked/>
                        <label htmlFor="exception1" > Строгое исключение</label></li>

                        <li><input type="radio" id="exception2"
                        name="exceptions" value="-1" />
                        <label htmlFor="exception2"></label> Нестрогое исключение</li>
                </ul> 
            </div>
            <div>
                <p className='col-xs-6'><strong>Дата старта: </strong></p>
                <DateTimePicker
                    pickerId='startDate'>
                </DateTimePicker>
            </div>
            <div>
                <p className='col-xs-6'><strong>Тип маршрута: </strong></p>
                    <ul className='col-xs-6 list-mode'>
                        <li title='Тип основан на времени движения'>
                            <input 
                                type='radio' 
                                name='mode' 
                                value='fastest' />
                            <span>  Быстрый</span>
                        </li>
                        
                        <li title='Тип основан на комбинации времени движения и расстояния'>
                            <input 
                                type='radio' 
                                name='mode' 
                                value='balanced'  
                                defaultChecked/>
                            <span>  Оптимальный</span>
                        </li>
                    </ul>
            </div>
            <div>
                <p className='col-xs-6'><strong>Расход топлива, л/100км: </strong></p>
                <p className='col-xs-6'>
                    <input 
                        value={this.state.fuelConsumption}
                        placeholder='Введите расход' 
                        name='fuelConsumption' 
                        id='fuelConsumption' 
                        onChange={this.onChangeFuel}/>
                </p>
                <p className='col-xs-12 error error-fuelConsumption'>* Расход не должен быть более 50л/100км.</p>
            </div>
            <div>
                <p className='col-xs-6'><strong>Ввод разных значений расхода топлива </strong></p>
                <p className='col-xs-6'>
                    <input 
                          type='checkbox'
                          name='checkFuel' 
                          onChange={this.checkDiffValueOfFuel}/>
                </p>
            </div>
            <div>
                <p className='col-xs-6'><strong>Цена 1 л. топлива, EUR: </strong></p>
                <p className='col-xs-6'>
                    <input 
                          value={this.state.fuelCost}
                          placeholder='Стоимость топлива' 
                          name='fuelCost' 
                          id='fuelCost' 
                          onChange={this.onChangeFuel}/>
                </p>
                <p className='col-xs-12 error error-fuelCost'>* Стоимость не должна быть более 5 EUR.</p>
            </div>
            <div>
                <p className='col-xs-6'><strong>Ставка на километр, EUR: </strong></p>
                <p className='col-xs-6'>
                    <input 
                          value={this.state.additCosts}
                          placeholder='Ставка на км' 
                          name='additCosts' 
                          id='additCosts' 
                          onChange={this.onChangeFuel}/>
                </p>
                <p className='col-xs-12 error error-additCosts'>* Стоимость не должна быть более 100 EUR.</p>
            </div>
      </Fragment>        
      );
    }
  }
  
  export default connect(
    state => state,
    dispatch => ({
      onChange: (searchBox) => {
      dispatch(searchBox)
    }
  })
  )(MainSettings);
  

  