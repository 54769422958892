import React, {Component} from 'react';
import {connect} from 'react-redux';
import WindowWithCoordsController from './WindowWithCoordsController';
import {handleMapClick, cleanMap, addPoint, drawLine, updateMarker, updateSearchBox, deleteMarker, dragMarker,} from './../Map/MapController';

const WindowWithCoordsCtr=new WindowWithCoordsController();

class WindowWithCoords extends Component{
    constructor(props){
        super(props);
        this.state={
            flagHideCoord: true,
            flagHideWindow: true,
            disabledBtn: true,
            valueLat: "", 
            valueLng: "",
            valueLatLng: ""
        }

        this.openInfoWindow=WindowWithCoordsCtr.openInfoWindow.bind(this);
        this.changeInputCoord=WindowWithCoordsCtr.changeInputCoord.bind(this);
        this.hideMenuWithCoord=WindowWithCoordsCtr.hideMenuWithCoord.bind(this);
        this.onChangeInputWithCoord=WindowWithCoordsCtr.onChangeInputWithCoord.bind(this);
        this.handleMapClick=handleMapClick.bind(this);
        this.cleanMap=cleanMap.bind(this);
        this.addPoint=addPoint.bind(this);
        this.drawLine=drawLine.bind(this);
        this.updateMarker=updateMarker.bind(this);
        this.updateSearchBox=updateSearchBox.bind(this);
        this.deleteMarker=deleteMarker.bind(this);
       // this.clickMarker=clickMarker.bind(this); import from MapContr-r
        this.dragMarker=dragMarker.bind(this);
    }

    render(){
        return (
            <div className='wrap-coord'>
                <div style={{minWidth: '70px'}}>
                    <p style={{display: 'inline-block'}}>
                        <span 
                            title='Для добавления маркера достаточно кликнуть по карте.' 
                            className='glyphicon glyphicon-info-sign' 
                            onClick={this.openInfoWindow}>   
                        </span>
                    </p>
                    <p 
                        style={{display: 'inline-block'}}
                        title='Изменить способ ввода координат' 
                        onClick={this.changeInputCoord}> 
                            <span 
                                className='glyphicon glyphicon-menu-down'>
                            </span>
                    </p>
                    <p 
                        style={{display: 'inline-block'}} 
                        onClick={this.hideMenuWithCoord} 
                        className='btn-hidenMenu'> 
                            <span 
                                className='glyphicon glyphicon-resize-small' 
                                title='Скрыть меню'>
                            </span>
                    </p>
                </div>
                <div className='accordeon-coords'>
                    <div className='accordeon-wrap-coord'>
                        <div>
                            <input 
                                name='point-lat' 
                                className='point-lat' 
                                placeholder='Введите широту' 
                                value={this.state.valueLat} 
                                onChange={this.onChangeInputWithCoord}/>
                            <p className='error error-lat'>Диапазон вводимых значений: -90..90</p>
                        </div>
                        <div>
                            <input  
                                name='point-lng' 
                                className='point-lng' 
                                placeholder='Введите долготу' 
                                value={this.state.valueLng} 
                                onChange={this.onChangeInputWithCoord}/>
                            <p className='error error-lng'>Диапазон вводимых значений: -180..180</p>
                        </div>
                        <div>
                            <button 
                                className='btn btn-add-point' 
                                onClick={this.handleMapClick} 
                               >Добавить 
                                    <span 
                                        title='Добавить точку' 
                                        className='glyphicon glyphicon-map-marker'>
                                    </span>
                            </button>
                        </div>
                    </div>
                    <div 
                        className='accordeon-wrap-bothCoord'>
                            <input  
                                name='input-latLng' 
                                className='input-latLng' 
                                placeholder='широта, долгота' 
                                onKeyUp={this.onChangeInputWithCoord} />
                            <p 
                                className='error error-latLng'>Формат: "широта(-90..90), долгота(-180..180)".
                            </p>
                            <div>
                                <button 
                                    className='btn btn-add-point' 
                                    onClick={this.handleMapClick} 
                                    disabled={this.state.disabledBtn}>Добавить 
                                        <span 
                                            title='Добавить точку' 
                                            className='glyphicon glyphicon-map-marker'>
                                        </span>
                                </button>
                            </div>
                    </div>
                </div> 
            </div>
        );
    }
}
export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point)
    }
  })
  )(WindowWithCoords);