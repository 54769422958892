import React, {Component} from 'react';
import Modal from 'react-modal';
import './Modal.css';
import ModalController from './ModalController';

const modalController=new ModalController();

const customStyles = {
  content : {
    maxWidth              : '450px',
    minWidth              : '250px',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '0'
   
  }
};

class ModalWindow extends Component {
    constructor(props) {
        super(props);
        this.closeModal=modalController.closeModal.bind(this);
        this.afterOpenModal=modalController.afterOpenModal.bind(this);
    } 
 
    render() {
      return (
        <Modal
            isOpen={this.props.visible}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
        >
        <div className="modal-window" >
            <div 
                className='confirm-title' 
                ref={subtitle => this.subtitle = subtitle}>
                    <span 
                        className='glyphicon glyphicon-info-sign'>
                    </span>
                    <span 
                        className='modal-title'>{this.props.title}
                    </span> 
                    <span 
                        onClick={this.closeModal} 
                        className='glyphicon glyphicon-remove-circle'>
                    </span>
            </div>
            <div className='modal-text'>{this.props.message}</div>
        </div>
        </Modal>
      );
    }
}
Modal.setAppElement('body');    //Для избежания ошибки!!! Warning: react-modal: App element is not defined. Please use `Modal.setAppElement                                     (el)` or set `appElement={el}`.

export default ModalWindow;