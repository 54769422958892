import React, {
    Component, Fragment
  } from 'react';
import  TruckSettingsController from './TruckSettingsController';
import {connect} from 'react-redux';
import './TruckSettings.css';

const TruckSettingsCtr=new TruckSettingsController();

class TruckSettings extends Component {
    constructor(props){
        super(props);   
        this.state={
            showParams: false,
            showSettings: false
        }

        this.changeTypeAuto=TruckSettingsCtr.changeTypeAuto.bind(this);
        this.showSettings=TruckSettingsCtr.showSettings.bind(this);
    }

    render() {
      return (  
        <Fragment>
            <div className='accordeon'>
                <div className='col-xs-6'><strong>  Грузовое авто</strong></div>
                <div className='col-xs-6'>
                    <input 
                        type='checkbox' 
                        name='truck' 
                        onChange={this.changeTypeAuto} 
                        defaultChecked
                        />
                    <div  className='right'  onClick={this.showSettings}  style={{cursor: 'pointer'}}>
                      <p className='right' style={{marginTop: '-2px'}}>Параметры</p>
                      <span 
                        className='right glyphicon glyphicon-cog truck-settings' 
                        title='Параметры грузовых автомобилей'>  
                      </span>
                    </div>
                </div>
            </div>    
        </Fragment>        
      );
    }
  }
  
  export default connect(
    state => state,
    dispatch => ({
      onChange: (searchBox) => {
      dispatch(searchBox)
    }
  })
  )(TruckSettings);
  

  