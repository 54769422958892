import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import WindowWithCoords from './../WindowWithCoords/WindowWithCoords';
import FuelStations from './../FuelStations/FuelStations';
import {handleMapClick, dragMarker, cleanMap, addPoint, updateMarker, updateSearchBox, drawLine} from './MapController';
import './Map.css';

const google =window.google;

class Map extends Component{
    constructor(props){
        super(props);
      //  this.clickMarker=clickMarker.bind(this); import from MapContr-r
        this.cleanMap=cleanMap.bind(this);
        this.dragMarker=dragMarker.bind(this);
        this.addPoint=addPoint.bind(this);
        this.updateMarker=updateMarker.bind(this);
        this.updateSearchBox=updateSearchBox.bind(this);
        this.drawLine=drawLine.bind(this);
    }
    componentDidMount(){
        const mapOptions = {
            zoom: 5,
            center: new google.maps.LatLng(54, 23),
            mapTypeId: google.maps.MapTypeId.ROADMAP
        };
        const map = new google.maps.Map(document.getElementById('wrapper-map'), mapOptions);
        const polyline = new google.maps.Polyline({
              strokeColor: '#008080',
              strokeOpacity: 1.0,
              strokeWeight: 2,
              map: map
        });
        map.addListener('click', handleMapClick.bind(this));
        this.props.onChange({type: 'SAVE_MAP', map: map});
        this.props.onChange({type: 'SAVE_POLY', line: polyline}); 
    }

    render(){
        return (
            <Fragment>
                <div 
                    id='wrapper-map'>
                </div>
                <WindowWithCoords 
                    counterPoints={this.props.counterPoints} 
                    onChangeCounterPoints={this.props.onChangeCounterPoints} 
                    resetModalWindow={this.props.resetModalWindow}>
                </WindowWithCoords>
                <FuelStations
                    resetModalWindow={this.props.resetModalWindow}>
                </FuelStations>
            </Fragment>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point)
    }
  })
)(Map);