import React, {Component} from 'react';
import Modal from 'react-modal';
import ModalConfirmController from './ModalConfirmWithTextController';

const modalController=new ModalConfirmController();
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '0 0 10px 0',
    maxWidth              : '450px'
  }
};

class ConfirmWindowWithTextInput extends Component {
    constructor(props) {
        super(props);
        this.state={
            open: this.props.open,
            inputValue: '', 
            disableBtnOk: true
        };
        this.closeModal=modalController.closeModal.bind(this);
        this.saveChange=modalController.saveChange.bind(this);
        this.afterOpenModal=modalController.afterOpenModal.bind(this);
        this.changeInputValue=modalController.changeInputValue.bind(this);
    }
 
    render() {
        return (
          <Modal
                isOpen={this.props.open}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                style={customStyles}
          >
              <div className="modal-window row" >
                  <div 
                      className='confirm-title col-xs-12' 
                      ref={subtitle => this.subtitle = subtitle}>
                          <span 
                              className='glyphicon glyphicon-info-sign'>
                          </span>
                          <span 
                              className='modal-title'> {this.props.title}
                          </span> 
                          <span 
                              onClick={this.closeModal} 
                              className='glyphicon glyphicon-remove-circle'>
                          </span>
                  </div>
                  <div 
                      className='modal-text col-xs-12'>
                          <p>{this.props.question}</p>
                          <p>
                              <input 
                                  type='text' 
                                  placeholder='Название' 
                                  className='modal-input' 
                                  value={this.state.inputValue} 
                                  onChange={this.changeInputValue}/>
                          </p>
                          <p className='input-error'>Введите не менее 4-ех символов.</p>
                  </div>
                  <div 
                      className='col-xs-12 row center'>
                          <div className='center col-xs-6'>
                              <input 
                                  type='button' 
                                  value='Отмена' 
                                  onClick={this.closeModal}/>
                          </div>
                          <div className='center col-xs-6'>
                              <input 
                                  type='button' 
                                  value='Применить' 
                                  disabled={this.state.disableBtnOk} 
                                  className='modal-button-ok' 
                                  onClick={this.saveChange}/>
                          </div>
                  </div>     
              </div>
          </Modal>
        );
    }
}

Modal.setAppElement('body');    //Для избежания ошибки!!! Warning: react-modal: App element is not defined. Please use `Modal.setAppElement                                     (el)` or set `appElement={el}`.

export default ConfirmWindowWithTextInput;