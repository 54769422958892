import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import FuelStationsController from './FuelStationsController';

const $=window.$;
const FuelStationsCtr=new FuelStationsController();

class FuelStations extends Component{
    constructor(props){
        super(props);
        
        this.showFuelStations=FuelStationsCtr.showFuelStations.bind(this);
        this.loadPointsInfo=FuelStationsCtr.loadPointsInfo.bind(this);
        this.hideFuelStations=FuelStationsCtr.hideFuelStations.bind(this);
        this.showPointOnMap=FuelStationsCtr.showPointOnMap.bind(this);
    }

    componentDidMount(){
        $('.wrap-fuelStations').resizable({
            ghost: true
          });
        $('.wrap-fuelStations').draggable();
    }

    render(){
        const rows=this.props.nearestCoords.map((item, index)=>{
            return <tr key={index} onClick={this.showPointOnMap}><td>{index+1}</td><td>{item.title}</td><td>{item.lng+', '+item.lat}</td></tr>
        });
        return(
            <Fragment>
                <button 
                    className='btn btn-default stations-layer' 
                    title='Отобразить заправки' 
                    onClick={this.showFuelStations}>           
                </button>
                <div 
                    className='wrap-fuelStations'>
                    <div>
                        <span 
                            className="btn glyphicon glyphicon-download-alt" 
                            title="Загрузить информацию о ближайших заправках в формате xlsx" 
                            onClick={this.loadPointsInfo}>
                        </span>
                        <span 
                            className='btn glyphicon glyphicon-remove right' 
                            onClick={this.hideFuelStations}>
                        </span>
                    </div>
                    <table>
                        <caption align='left'>Ближайшие заправки</caption>
                        <thead>
                            <tr><th>#</th><th>Заправка</th><th>Координаты заправки</th></tr>
                        </thead>
                        <tbody className='table-fuelStations'>
                            {rows}
                        </tbody>
                    </table>
                </div>
            </Fragment>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point)
    }
  })
  )(FuelStations);