import React, { Component, Fragment } from 'react';
import {connect } from 'react-redux';
import { createStore} from 'redux';
import reducer from '../../reducers/reducer';
import Modal from 'react-modal';
import Map from '../Map/Map.js';
import Settings from '../Settings/Settings';
import AdditPanel from '../AdditPanel/AdditPanel';
import MainTable from '../MainTable/MainTable';
import CountryDescription from '../ContryDescription/CountryDescription';
import MainController from './MainController';
import Header from '../../components/Header/Header';
import ModalWindow from '../../components/Modal/Modal';
import DescriptionTable from '../../components/DescriptionTable/DescriptionTable';
import WindowWithSettings from '../../components/WindowWithSettings/WindowWithSettings';
import WialonController from '../../components/Wialon/WialonController';
import './Main.css';

const MainCtr=new MainController();
const WialonCtr=new WialonController();
const store = createStore(reducer, window.__REDUX_DEVTOOLS_EXTENSION__&&window.__REDUX_DEVTOOLS_EXTENSION__());

const customStyles = {
  content : {
    maxWidth              : '450px',
    minWidth              : '250px',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '0'
  }
};

  class Main extends Component {
      constructor(props){
          super(props);
          this.state={
              authorized: true,
              counterPoints:0,
              modalText:'',
              modalTitle:'',
              store: store, 
              userName: '',
              visibleModal: false
          }

          this.closeModal=MainCtr.closeModal.bind(this);
          this.getCodesAndBoundaries=WialonCtr.loadingPostalCodes.bind(this);
          this.onChangeCounterPoints=MainCtr.onChangeCounterPoints.bind(this);
          this.resetModalWindow=MainCtr.resetModalWindow.bind(this);
          this.initWialonSession=WialonCtr.initWialonSession.bind(this);
          this.loadCustomProperties=WialonCtr.loadCustomProperties.bind(this);
          this.loadZones=WialonCtr.loadZones.bind(this);
          this.loadRoutes=WialonCtr.loadRoutes.bind(this);
          this.loadObjects=WialonCtr.loadObjects.bind(this);    
      }
    
      componentDidMount(){
        const params = window.location.search.replace('?','').split('&').reduce(
            function(p,e){
                var a = e.split('=');
                p[ decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
                return p;
            },{});
        if(params['sid']){
            this.initWialonSession({sid: params['sid']});
            this.getCodesAndBoundaries();
        }
        else if(params['access_token']){
            this.initWialonSession({token: params['access_token']});
           // this.getCodesAndBoundaries();
        }   
        else{
            this.setState({
            authorized: false
          });
        }
      }

      render() {
          if(this.state.authorized){
              return (
                  <Fragment>
                      <Header 
                          user={this.state.userName}>
                      </Header>
                      <div className='main-wrap row'>
                          <div id='left-panel' className='col col-xxs-12 col-xs-6 col-sm-5 col-md-4 col-lg-3'>
                              <Settings
                                  counterPoints={this.state.counterPoints}
                                  onChangeCounterPoints={this.onChangeCounterPoints}
                                  resetModalWindow={this.resetModalWindow}>
                              </Settings>
                          </div>
                          <div id='right-panel' className='col col-xxs-12 col-xs-6 col-sm-7 col-md-8 col-lg-9' >
                                <Map
                                    counterPoints={this.state.counterPoints}
                                    onChangeCounterPoints={this.onChangeCounterPoints}
                                    resetModalWindow={this.resetModalWindow}> 
                                </Map>    
                                <AdditPanel 
                                    resetModalWindow={this.resetModalWindow}
                                    onChangeCounterPoints={this.onChangeCounterPoints}>
                                </AdditPanel> 
                                <div className='wrap-result-table'>                   
                                    <MainTable></MainTable>
                                    <CountryDescription></CountryDescription>
                                </div>
                          </div>
                      </div>
                      <DescriptionTable></DescriptionTable>
                      <WindowWithSettings resetModalWindow={this.resetModalWindow}></WindowWithSettings>
                      <ModalWindow
                          message={this.state.modalText}
                          title={this.state.modalTitle}
                          visible={this.state.visibleModal}
                          window={this.closeModal}>
                      </ModalWindow>
                      <div id='preloader'></div>
                  </Fragment>    
              );
        } 
          else{
            return(
                <Modal
                    isOpen={true}
                    onAfterOpen={this.afterOpenModal}
                    onRequestClose={this.closeModal}
                    style={customStyles} >
                        <div className="modal-window" >
                            <div className='confirm-title'>
                                <h4>
                                    <span className='glyphicon glyphicon-info-sign'></span>
                                    <span className='modal-title'>  Внимание</span> 
                                </h4>
                            </div>
                            <div className='modal-text'>Приложение доступно для авторизованных пользователей. 
                                <a href='/logout'>
                                    <button className='btn btn-logout'>Перейти к форме логирования 
                                        <span className='glyphicon glyphicon-hand-right'></span>
                                    </button>
                                </a>
                            </div>
                          </div>
                  </Modal>
              );
            }
        }
    }
  
  export default connect(
      state => state,
      dispatch => ({
        onChange: (point) => {
        dispatch(point)
      }
    })
  )(Main);
  
