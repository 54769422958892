import React, { Component, Fragment} from 'react';
import {connect} from 'react-redux';
import ConfirmWindow from '../../components/ModalConfirm/ModalConfirm';
import ConfirmWindowWithText from '../../components/ModalConfirm/ModalConfirmWithTextInput';
import WialonRoutesController from './WialonRoutesController';
import {cleanMap, drawLine, dragMarker, addPoint, deletePoints, updateMarker, updateSearchBox} from '../Map/MapController';

const $=window.$;
const WialonRoutesCtr=new WialonRoutesController();

class WialonRoutes extends Component{
    constructor(props){
        super(props);
        this.state={
            activeBtnDelete: true,
            confirmDeleteRoute: false,
            openConfirm:false
        };

        this.changeSelectOption=WialonRoutesCtr.changeSelectOption.bind(this);
        this.saveRoute=WialonRoutesCtr.saveRoute.bind(this);
        this.changeStatusWindow=WialonRoutesCtr.changeStatusWindow.bind(this);
        this.saveRoutingName=WialonRoutesCtr.saveRoutingName.bind(this);
        this.deleteRoute=WialonRoutesCtr.deleteRoute.bind(this);
        this.openConfirmDeleteRoute=WialonRoutesCtr.openConfirmDeleteRoute.bind(this);
        this.openConfirm=WialonRoutesCtr.openConfirm.bind(this);
        this.cleanMap=cleanMap.bind(this);
        this.drawLine=drawLine.bind(this);
       // this.clickMarker=clickMarker.bind(this); import from MapContr-r
        this.dragMarker=dragMarker.bind(this);
        this.addPoint=addPoint.bind(this);
        this.deletePoints=deletePoints.bind(this);
        this.updateMarker=updateMarker.bind(this);
        this.updateSearchBox=updateSearchBox.bind(this);
    }
    componentDidMount(){
        const self=this;
        $('.select-route').change(function(event){
            if(event.target.value!=='Выберите маршрут'){
                self.setState({
                    activeBtnDelete: false
                });
            }
            else{
                self.setState({
                    activeBtnDelete: true
                });
            }
            self.changeSelectOption(event.target.value);
        });
    }
    render(){
        const routes= this.props.routes.map((item, index) => {
            return <option key={index+1} value={item.value}>{item.name}</option>;
        });
        return(
            <Fragment>
                <div className='wrap-add-delete'>
                    <select className='col-xs-12 select-route'>
                        <option>Выберите маршрут</option>
                        {routes}
                    </select>
                    <button 
                        title="Сохранить маршрут в Wialon" 
                        className='btn col-xs-5  save-route'  
                        onClick={this.saveRoute}>
                            <span>Сохранить </span> 
                            <span className="glyphicon glyphicon-save"></span>    
                    </button>
                    <button  
                        disabled={this.state.activeBtnDelete}
                        title="Удалить маршрут из Wialon" 
                        className='btn col-xs-5 col-xs-offset-2 delete-route'  
                        onClick={this.openConfirmDeleteRoute}>
                            <span>Удалить </span>
                            <span className='glyphicon glyphicon-remove'></span>
                    </button> 
                </div>
                <ConfirmWindowWithText
                    open={this.state.openConfirm}
                    title='Сохранение маршрута'
                    question='Введите название маршрута для сохранения в системе Wialon.'
                    changeStatusWindow={this.changeStatusWindow} 
                    saveChanges={this.saveRoutingName}  />
                <ConfirmWindow
                    open={this.state.confirmDeleteRoute}
                    title='Удаление маршрута'
                    question='Вы действительно хотите удалить маршрут из Wialon?'
                    deleteRoute={this.deleteRoute}
                    changeStatusWindow={this.openConfirm} 
                  />
            </Fragment>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point)
    }
  })
)(WialonRoutes);