class ModalConfirmController{
   
  afterOpenModal() {
      this.subtitle.style.fontSize='24px';
   }
    deleteRoute(){
      this.props.deleteRoute();
      this.closeModal();
   }
   closeModal() {
      this.props.changeStatusWindow();
   }
}

export default ModalConfirmController;