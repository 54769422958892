export default function allRoutes(tracks = {}, action) {
    if (action.type === 'SAVE_TRACK') {
        tracks[action.index]={info:action.info};
      return tracks;
    } 
    else if(action.type==='CHANGE_TRACK'){
      tracks[action.index]['info'][action.changingItem]=action.value;
      return tracks;
  }
     else if (action.type === 'DELETE_TRACK') {
      for(let i in tracks){
        if(+i===action.item){
          delete tracks[i];
        }
        else if(+i>action.item){
          tracks[+i-1]=tracks[i];
          delete tracks[i];
        }
      }
      return tracks;
    }
    else if(action.type==='DELETE_TRACKS'){
      if(action.activeTrack){
        tracks={};
        tracks['1']=action.track;
        return tracks;
      }
      else{
        return tracks={};
      } 
    }
    return tracks;
  }