class ModalController {
  
    afterOpenModal() {
      this.subtitle.style.fontSize = '24px';
    }

    closeModal() {
      this.props.window(false);
    }
    
}

export default ModalController;