const $=window.$;
const wialon=window.wialon;
class ModalConfirmController{
      
     closeModal() {
        $('.window-with-params').css('display', 'none');
        $('.main-wrap').css('opacity', 1).css('pointer-events', 'auto');
     }

     resetSettings(){
        const newCustomProps={
            emissionType5: false, 
            emissionType6: true,
            hazardousGood1: false,
            hazardousGood2: false,
            hazardousGood3: true,
            tunnelCategory1: false,
            tunnelCategory2: false,
            tunnelCategory3: false,
            tunnelCategory4: false,
            numberOfAxles2: false,
            numberOfAxles3: false,
            numberOfAxles4: false,
            numberOfAxles5: true,
            numberOfAxles6: false,
            numberOfAxles7: false,
            fullWeight: "40",
            weightPerAxle: "10",
            height: "4",
            length: "16.5",
            width: "2.55"
        };
        this.props.onChange({
          type: 'SAVE_CUSTOM_PROPS',
          props: newCustomProps
        });
     }

     saveSettings(){
        const sess = wialon.core.Session.getInstance();
        const user = sess.getCurrUser();
        
        const hazardousGood=$('input[name="shippedHazardousGoods"]:checked').val();
        const tunnelCategory=$('input[name="tunnelCategory"]:checked').val();
        const numberAxles=$("input[name='numberOfAxles']:checked").val();
        const emissionType=$("input[name='emissionTypes']:checked").val();
        const fullWeight=$('input[name="fullWeight"]').val();
        const height=$('input[name="height"]').val();
        const length=$('input[name="length"]').val();
        const width=$('input[name="width"]').val();
        const weightPerAxle=$('input[name="weightPerAxle"]').val();

        const newCustomProps={
            emissionType5: (emissionType==="5")?true:false, 
            emissionType6: (emissionType==="6")?true:false,
            hazardousGood1: (hazardousGood==="1")?true:false,
            hazardousGood2: (hazardousGood==="2")?true:false,
            hazardousGood3: (hazardousGood==="3")?true:false,
            tunnelCategory1: (tunnelCategory==="B")?true:false,
            tunnelCategory2: (tunnelCategory==="C")?true:false,
            tunnelCategory3: (tunnelCategory==="D")?true:false,
            tunnelCategory4: (tunnelCategory==="E")?true:false,
            numberOfAxles2: (numberAxles==="2")?true:false,
            numberOfAxles3: (numberAxles==="3")?true:false,
            numberOfAxles4: (numberAxles==="4")?true:false,
            numberOfAxles5: (numberAxles==="5")?true:false,
            numberOfAxles6: (numberAxles==="6")?true:false,
            numberOfAxles7: (numberAxles==="7")?true:false,
            fullWeight: fullWeight,
            weightPerAxle: weightPerAxle,
            height: height,
            length: length,
            width: width
        };
        user.updateCustomProperty('Параметры в Километражнике', JSON.stringify(newCustomProps), function(code){
            if(code===0){
                $('.window-with-params').css('display', 'none');
                $('.main-wrap').css('opacity', 1).css('pointer-events', 'auto');
            }
            else{
                this.props.resetModalWindow('Не удалось сохранить пользоваельские настройки. Повторите попытку', 'Внимание');
            }     
        });
     }

     showAdditInfo(event){
        const classList=Array.prototype.slice.call(event.target.classList);
        const textTunnel='Категория туннеля указывает, какие опасные грузы не допускаются в регулируемый туннель. Категории варьируются от B до E, где E является наименее ограничительным. При выборе категории при построении маршрута будут исключены дороги с туннелями, с категориями равными выбранной или больше выбранной. Например, при выборе категории C будут исключены C, D, E, при выборе D - D и E.';
        const textGoods='Построенный для грузового транспорта маршрут будет исключать дороги, по которым запрещено перевозить выбранные опасные материалы. При выборе  "Любые опасные материалы" при построении маршрута будут учтены только те дороги, по которым разрешен провоз горючих, радиоактивных, легковоспламеняющихся веществ, газа, а также веществ, вредных для воды. По умолчанию маршрут строится без учета опасных веществ.';
        const textEcoSt='Экологический стандарт регулирует содержание вредных веществ в выхлопных газах. Он указывается для расчета стоимости проезда по дорогам.';
        const value=(classList.indexOf('info-tunnelCategory')!==-1)?textTunnel:(classList.indexOf('info-hazardousGoods')!==-1)?textGoods:textEcoSt;
        this.props.resetModalWindow(value, 'Информация');
    }

    showParamsTruck(event){
        const target=event.target;
        this.setState({
            showParams: !this.state.showParams
        },
        function(){
            if(this.state.showParams){
                $(target).removeClass('glyphicon-chevron-down');
                $(target).addClass('glyphicon-chevron-up');
                $('.paramsOfTruck').css('display', 'block');
            }
            else{
                $(target).removeClass('glyphicon-chevron-up');
                $(target).addClass('glyphicon-chevron-down');
                $('.paramsOfTruck').css('display', 'none');
            }
        });
    }

    onChangeEmission(){
        const sess = wialon.core.Session.getInstance();
        const user = sess.getCurrUser();
        const emissionType=$("input[name='emissionTypes']:checked").val();
       
        this.props.customProps.emissionType5=(emissionType==="5")?true:false;
        this.props.customProps.emissionType6=(emissionType==="6")?true:false;
        user.updateCustomProperty('Параметры в Километражнике', JSON.stringify(this.props.customProps)); 
    }

    onChangeNumbersOfAxles(event){
        let height, width, length, limitedWeight, weightPerAxle;
        let newState={};
        const value=event.target.value;
    
        switch(value){
            case '2':
                height=4;
                width=2.55;
                length=12;
                limitedWeight=18;
                weightPerAxle=6;
            break;
            case '3':
                height=4;
                width=2.55;
                length=12;
                limitedWeight=26;
                weightPerAxle=8;
            break;
            case '4':
                height=4;
                width=2.55;
                length=18.75;
                limitedWeight=36;
                weightPerAxle=10;
            break;
            case '5':
                height=4;
                width=2.55;
                length=16.5;
                limitedWeight=40;
                weightPerAxle=10;
            break;
            case '6':
                height=4;
                width=2.6;
                length=23;
                limitedWeight=40;
                weightPerAxle=10;
            break;
            case '7':
                height=4;
                width=2.6;
                length=23;
                limitedWeight=55.5;
                weightPerAxle=10;
            break;
            default:
            break;
        }
        this.props.customProps['height']=height;
        this.props.customProps['width']=width;
        this.props.customProps['length']=length;
        this.props.customProps['fullWeight']=limitedWeight;
        this.props.customProps['weightPerAxle']=weightPerAxle;
           
        newState['height']=height;
        newState['width']=width;
        newState['length']=length;
        newState['fullWeight']=limitedWeight;
        newState['weightPerAxle']=weightPerAxle;
        this.setState(newState);
    }

    handleInputChanged(event) {
        let newState = {};
        let val;
        const name=event.target.name;
        const value = event.target.value.replace(' /\\D/g', '');
    
        switch(name){
            case 'length':
                val=300;
                break;
            case 'height':
                val=50;
                break;
            case 'fullWeight':
                val=1000;
                break;
            case 'width':
                val=50;
                break;
            case 'weightPerAxle':
                val=1000;
                break;
            default:
                break;
        }
        
        if (+event.target.value <= val) {
            newState[name]=value;       
            this.props.customProps[name]=value;
            this.props.onChange({
                type: 'SAVE_CUSTOM_PROPS',
                props: this.props.customProps
              });
            this.setState(newState);
            
            $('.error-'+name).css('display', 'none');
        }
        else{
            $('.error-'+name).css('display', 'block');
        }
    } 
  }
  
  export default ModalConfirmController;