const $=window.$;

class WindowWithCoordsController{
     
    openInfoWindow(){
        this.props.resetModalWindow('Для добавления точки достаточно кликнуть левой клавишей мыши по карте.', 'Информация', true);
    }

    changeInputCoord(event){
        const target=event.target;
        this.setState({
            flagHideCoord: !this.state.flagHideCoord
        }, function(){
            if(!this.state.flagHideCoord){
                $(target).removeClass('glyphicon-menu-up');
                $(target).addClass('glyphicon-menu-down');
                $('.accordeon-wrap-bothCoord').css('display', 'none');
                $('.accordeon-wrap-coord').css('display', 'block');
            }
            else{
                $(target).removeClass('glyphicon-menu-down');
                $(target).addClass('glyphicon-menu-up');
                $('.accordeon-wrap-bothCoord').css('display', 'block');
                $('.accordeon-wrap-coord').css('display', 'none');
            }
        });
    }

    hideMenuWithCoord(event){          
        const target=event.target;
        this.setState({
            flagHideWindow: !this.state.flagHideWindow
        }, function(){
            if(!this.state.flagHideWindow){
                $(target).removeClass('glyphicon-resize-small');
                $(target).addClass('glyphicon-resize-full');
                $('.accordeon-coords').css('display', 'none');
            }
            else{
                $(target).removeClass('glyphicon-resize-full');
                $(target).addClass('glyphicon-resize-small');
                $('.accordeon-coords').css('display', 'block');
            }
        });
    }

     onChangeInputWithCoord(event){ 
        const name=event.target.name;
        const value=event.target.value;
        const changingValue=value.replace('+', '').replace(' /\\D/g','');
        
        if(name==='input-latLng'&&(/^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/).test(value)){
            $('.error-latLng').css('display', 'none');
            this.setState({
                disabledBtn: false
            });
        }
        else if(name==='input-latLng'){
            $('.error-latLng').css('display', 'block');
            this.setState({
                valueLatLng: changingValue,
                disabledBtn: true
            });
        }

        if((value==='-'||(+value>=-90&&+value<=90))&&name==='point-lat'){
            this.setState({
                valueLat: changingValue
            });   
            $('.error-lat').css('display', 'none');     
        }
        else if(name==='point-lat'){                        
                $('.error-lat').css('display', 'block');  
        }

        if((value==='-'||(+value>=-180&&+value<=180))&&name==='point-lng'){
            this.setState({
                valueLng: value
            });
            $('.error-lng').css('display', 'none');
        }
        else if(name==='point-lng'){
            $('.error-lng').css('display', 'block');   
        } 
    }
}

export default WindowWithCoordsController;