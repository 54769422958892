import  {
  Component
} from 'react';

class Home extends Component {
    componentWillMount(){
     window.location = 'https://hosting.wialon.com/login.html?access_type=-1&lang=ru&client_id=Kilometr&redirect_uri='+window.location.origin+'/main';
    }  
}

export default Home;
