export default function tabs(tabs = [], action) {
    if (action.type === 'ADD_TAB' && tabs.indexOf(action.item) === -1) {
        tabs.push(action.newItem);
        return tabs;
    } else if (action.type === 'DELETE_TAB') {
        const index = tabs.indexOf(action.item);
        tabs.splice(index, 1);
        tabs.forEach((tab, i) => {
            if (tab > action.item) {
                tabs.splice(i, 1, tab - 1);
            }
        });
        return tabs;
    }
    else if (action.type === 'DELETE_TABS') {
        return tabs=[];

    }
    return tabs;
}