const axios = require('axios');
const html2canvas=require('html2canvas');
const wialon = window.wialon;
const google=window.google;
const $ = window.$;

class AdditPanelController {

    addTab(){
        const tabs=this.props.tabs;
        const activeTab=$('.nav-tabs .active').text();
        
        if($('input[name="newTab"]').is(':checked')){
            let markers=[];
            this.props.markers.forEach(marker=>{
                    markers.push(new google.maps.Marker({
                        position: marker.getPosition(),
                        title: marker.getTitle(),
                        label: marker.getLabel()
                    }));
            });
            
            this.props.onChange({type: 'ADD_TAB', newItem: tabs.length+1, item: (+activeTab)});
            this.props.onChange({type:'SAVE_ALL_MARKERS', newMarker: markers, index: tabs.length});
            this.props.onChange({type: 'SAVE_TRACK', index: tabs.length, info:{
                footRows:this.props.footRows, 
                bodyRows: this.props.bodyRows, 
                countriesDescription: this.props.countriesDescription, 
                description: this.props.description, 
                markers: markers, 
                searchboxes: this.props.searchBoxes, 
                cost: this.props.totalCost, 
                minMax: this.props.minMaxCoords, 
                mainPolyline: this.props.mainPolylines, 
                allCoords: this.props.allCoords, 
                freeTollRoads: this.props.freeTollRoads }});
            this.props.onChange({type:'SAVE_SETTINGS', index: tabs.length,  item:{
                car: $("input[name='truck']").is(':checked'),
                mode: $("input[name='mode']:checked").val(),
                tollRoads: $("input[name='tollRoads']").is(':checked'),
                exception: $("input[name='exceptions']:checked").val(),
                fuelConsumption: $('#fuelConsumption').val(),
                inputCheckFuel: $("input[name='checkFuel']").prop('checked'),
                fuelCost: $('#fuelCost').val(),
                additCosts: $('#additCosts').val()
            }});
            this.setState({tabs: tabs}, function(){
                $('.nav-tabs li:nth-of-type(1)').addClass('active');
                $('input[name="newTab"]').prop('disabled', true);
            });
        }
        else{
            const allRoutes=this.props.allRoutes;
            const allMarkers=this.props.allMarkers;
            let sortCoord=[];
            if(this.props.allMarkers[+activeTab]){
                this.props.allMarkers[+activeTab].forEach(marker=>{
                    marker.setMap(null);   
                });
            }
            let updateIndex=(+activeTab<tabs.length)?tabs.length:tabs.length-1;
            this.updateTableAndBoxes(updateIndex); 
            this.props.onChange({type:'DELETE_ADDIT_MARKER', index: +activeTab});
            this.props.onChange({type:'DELETE_TRACK', item: +activeTab});
            this.props.onChange({type:'DELETE_TAB', item: +activeTab});
            this.props.onChange({type:'DELETE_SETTINGS', index: +activeTab});  
            this.setState({tabs: tabs});
            this.updateRoutes();
            
            for(let i in allMarkers){
                if(i!==''+tabs.length){
                    allMarkers[i].forEach(marker=>{
                        marker.setMap(null);
                    });
                }
                else {
                    allMarkers[i].forEach(marker=>{
                        marker.setMap(this.props.map);
                        sortCoord.push(marker.getPosition());
                    });
                }
            }

            this.props.onChangeCounterPoints(this.props.allMarkers[tabs.length+''].length);
 
            this.props.onChange({
                type: 'SORT_POINT',
                sortPoints: this.props.allMarkers[tabs.length+'']
            });
            this.props.onChange({
                type: 'SORT_COORD',
                sortCoord: sortCoord
            });
            
            $('.nav-tabs li').removeClass('active'); 
            $('.nav-tabs li:nth-of-type('+tabs.length+')').addClass('active');
            this.drawAnimateCircle(allRoutes[tabs.length]['info']['allCoords']);
            if(tabs.length>0){
                $('input[name="newTab"]').prop('checked', true);
            }
            if(tabs.length<=1){
                $('#btn-delete-allRoutes').prop('disabled', true);
                $('input[name="newTab"]').prop('disabled', true);
            }  
        }
    }

    activatingTab(event){
        let sortCoord=[];
        const allRoutes=this.props.allRoutes;
        const allMarkers=this.props.allMarkers;

        this.updateRoutes(event.target.textContent);
        this.drawAnimateCircle(allRoutes[event.target.textContent]['info']['allCoords']);
        this.props.onChangeCounterPoints(this.props.allMarkers[event.target.textContent].length);
 
        this.props.allMarkers[event.target.textContent].forEach(marker=>{
            sortCoord.push(marker.getPosition());
        });
        this.props.onChange({
            type: 'SORT_POINT',
            sortPoints: this.props.allMarkers[event.target.textContent]
        });
        this.props.onChange({
            type: 'SORT_COORD',
            sortCoord: sortCoord
        });
        if( this.props.additMarker){
            this.props.additMarker.setMap(null);
        }

        for(let i in allMarkers){
            if(i!==event.target.textContent){
                allMarkers[i].forEach(marker=>{
                    marker.setMap(null);
                });
            }
            else {
                allMarkers[i].forEach(marker=>{
                    marker.setMap(this.props.map);
                });
            }
        }
    
        $('.nav-tabs li').removeClass('active');
        $('.nav-tabs li:nth-of-type('+event.target.textContent+')').addClass('active');
        $('input[name="newTab"]').prop('checked', true);
         
        this.updateSettings(+event.target.textContent);
        this.updateTableAndBoxes(+event.target.textContent);
    }

    cleanAllRoutes(){
        this.props.onChange({
            type: 'DELETE_ALL_POINTS'
          });
       $('#btn-delete-allRoutes').prop('disabled', true);
        const activeTab=$('.nav-tabs .active').text();  
        this.props.additPolylines.forEach(item=>{
            item.setPath([]);
        });
        
        this.props.onChange({type:'DELETE_ADDIT_POLYLINES'});

        const allMarkers=this.props.allMarkers;
        const allRoutes=this.props.allRoutes;
        for(let i in allMarkers){
            if(i!==activeTab){
                allMarkers[i].forEach(marker=>{
                    marker.setMap(null);
                });
            }
            else{
                let sortCoord=[];
                this.props.onChange({
                    type: 'SORT_POINT',
                    sortPoints: allMarkers[i]
                });
                allMarkers[i].forEach(marker=>{
                    sortCoord.push(marker.getPosition());
                });

                this.props.onChange({
                    type: 'SORT_COORD',
                    sortCoord: sortCoord
                });
            }
    }
    
        this.setState({tabs:[1]});
        this.props.onChangeCounterPoints(allMarkers[activeTab].length);
        this.props.onChange({type:'DELETE_ALL_MARKERS', activeTrack: true, markers: allMarkers[activeTab]});
        this.props.onChange({type: 'DELETE_ALL_SETTINGS', activeTrack: true, settings: this.props.settings[activeTab]});
        this.props.onChange({type:'DELETE_TRACKS', activeTrack: true, track: allRoutes[activeTab]});
        this.props.onChange({type:'DELETE_TABS'});
        //this.props.onChange({type:'DELETE_TABS', activeTrack:true});
        this.props.onChange({type: 'ADD_TAB', newItem: 1});
        $('.nav-tabs li:nth-of-type(1)').addClass('active');
        $('input[name="newTab"]').prop('disabled', true);

    }

    updateRoutes(i){
        let strokeColor, index;
        const allRoutes=this.props.allRoutes;
        const tabs=this.props.tabs;
        if(i){
            index=i;
        }
        else{
            index=tabs.length+'';
        }

        if(this.props.polyline){
            this.props.polyline.setPath([]);
        }
        this.props.onChange({type: 'DELETE_CHECKED_POLYLINE'});
        this.props.onChange({type:'DELETE_ADDIT_POLYLINES'});
        this.props.checkedPolyline.forEach(item=>{
            item.setPath([]);
        });
        this.props.additPolylines.forEach(item=>{
            item.setPath([]);
        });
        this.props.mainPolylines.forEach(item=>{
            item.setPath([]);
        });
        this.props.markers.forEach(item=>{
            item.setMap(null);
        });

        for(let i in allRoutes){
            const freeTollRoads=allRoutes[i]['info']['freeTollRoads'];
            for(let key in freeTollRoads){
                const coords=freeTollRoads[key];
                coords.forEach(road=>{
                    let polyline;
                    let pathPoly=[];
                    road.forEach(coord=>{     
                        for(var a=0; a<coord.length; a=a+2){
                            pathPoly.push(new google.maps.LatLng({lat: coord[a], lng: coord[a+1]}));
                        }
                    });
                    
                    if(i!==index){
                        strokeColor='blue';
                    }
                    else if(key==='coordTollRoad'){
                        strokeColor='red';
                    }
                    else{
                        strokeColor='green';
                    }
                    polyline = new google.maps.Polyline({
                        geodesic: true,
                        strokeColor: strokeColor,
                        strokeOpacity: 1.0,
                        strokeWeight: (i!==index)?1.5:3,
                        map: this.props.map
                    });
            
                    polyline.setPath(pathPoly);
                    
                    if(i!==index){
                        this.props.onChange({type: 'SAVE_ADDIT_POLY', newPoly: polyline});
                    }
                    else{
                        this.props.onChange({type: 'SAVE_CHECKED_POLY', newPoly: polyline});
                    }
                });
            }
        }
    }

    updateTableAndBoxes(index){
        
        this.props.onChange({type: 'DELETE_FOOT_ROW'});
        this.props.onChange({type: 'DELETE_BODY_ROWS'});
        this.props.onChange({type: 'DELETE_COUNTRY_DESCRIPTION'});
        this.props.onChange({type: 'RESET_COST'});

        this.props.onChange({type: 'ADD_FOOT_ROW', footRow:this.props.allRoutes[index]['info']['footRows'][0]});
        this.props.onChange({type: 'ADD_BODY_ROW', bodyRows:this.props.allRoutes[index]['info']['bodyRows']});
        this.props.onChange({type:'SAVE_DESCRIPTION', description: this.props.allRoutes[index]['info']['description']});
        this.props.onChange({type: 'SAVE_COST', totalCost: this.props.allRoutes[index]['info']['cost']});        
        this.props.onChange({type:'SAVE_COUNTRY_DESCRIPTION', totalCostsByCountry: this.props.allRoutes[index]['info']['countriesDescription']});
        this.props.onChange({type: 'SAVE_ALL_COORDS', coords: this.props.allRoutes[index]['info']['allCoords']}); 
        this.props.onChange({
            type: 'SORT_SearchBox',
            sortSearchBoxes: this.props.allRoutes[index]['info']['searchboxes']
        });
    }


    updateSettings(index){
        const settings=this.props.settings[index];
        $("input[name='truck']").prop('checked', settings['car']);
        //$("input[name='mode']:checked").val(settings['mode']);
        $("input[name='tollRoads']").prop('checked', settings['tollRoads']);
       // $("input[name='exceptions']:checked").val(settings['exceptions']);
       // autoParams: truckParams,
       // $('#fuelConsumption').val(settings['fuelConsumption']);
        $("input[name='checkFuel']").prop('checked', settings['inputCheckFuel']);
       // $('#fuelCost').val(settings['fuelCost']);
       // $('#additCosts').val(settings['additCosts']);

    }
    
    savePDF(){
    
        const self=this;
        const user = wialon.core.Session.getInstance().getCurrUser().getName();
        
        html2canvas(document.querySelector("#wrapper-map"), {useCORS: true}).then(canvas => {
            const dataURL = canvas.toDataURL();
            const formData = new FormData();

            formData.append("image", dataURL);
            formData.append("user", user);
            formData.append("resultTable", JSON.stringify(self.props.bodyRows));
            formData.append("fuelStations", JSON.stringify(self.props.nearestCoords));
            formData.append("total", JSON.stringify(self.props.footRows));
        
            axios({
                method: 'post',
                url: '/savePDF',
                data: formData,
            })
            .then(function (response) {
                if (!response.data) {
                    self.props.resetModalWindow('Файл не может быть сохранен. Попробуйте еще раз или обратитесь в тех.поддержку.', 'Внимание', true);
                } else {
                    const link = document.createElement('a');
                    link.setAttribute('href', '/store/resultPDF-' + user + '.pdf');
                    link.setAttribute('download', 'resultPDF-' + user + '.pdf');
                    window.onload = link.click();
                }
            });
        }); 
    }

    saveXLSXFile() {
        const self = this;
        const user = wialon.core.Session.getInstance().getCurrUser().getName();

        axios.post('/saveResultXLSX', {
                body: self.props.bodyRows,
                total: self.props.footRows,
                user: user
            })
            .then(function (response) {
                if (!response.data) {
                    self.props.resetModalWindow('Файл не может быть сохранен. Попробуйте еще раз или обратитесь в тех.поддержку.', 'Внимание', true);
                } else {
                    const link = document.createElement('a');
                    link.setAttribute('href', '/store/resultTable-' + user + '.xlsx');
                    link.setAttribute('download', 'resultTable-' + user + '.xlsx');
                    window.onload = link.click();
                }
            });
    }

    saveGeofence() {
        this.setState({
            openConfirmGeo: !this.state.openConfirmGeo
        });
    }

    showDescription() {
        $('.wrap-description').show(250, function () {
            $('.wrap-description').css('right', 0);
        });
    }

    submitGeo(name) {
        const sid = wialon.core.Session.getInstance()['__cT'];
        const resourceId = wialon.core.Session.getInstance().getCurrUser()['$$user_accountId'];
        const self = this;
        axios.post('/saveGeofence', {
                coordinates: this.props.allCoords,
                sid: sid,
                name: name,
                resourceId: resourceId
            })
            .then(response => {
                if (response.data) {
                    self.props.resetModalWindow('Геозона сохранена', 'Внимание', true)
                } else {
                    self.props.resetModalWindow('При сохранении геозоны возникла ошибка. Попробуйте еще раз. Воможно, у пользователя недостаточно прав для сохранения геозоны. Обратитесь, пожалуйста, в техподдержку для консультации', 'Внимание', true)
                }
            });
    }

    changeStatusWindowGeo() {
        this.setState({
            openConfirmGeo: !this.state.openConfirmGeo
        });
    }

}
export default AdditPanelController;