const $ = window.$;

class ModalConfirmController {

  afterOpenModal() {
    this.subtitle.style.fontSize = '24px';
  }

  saveChange() {
    this.props.saveChanges(this.state.inputValue);
    this.closeModal();
  }

  closeModal() {
    this.props.changeStatusWindow();
  }

  changeInputValue(event) {
    this.setState({
      inputValue: event.target.value
    }, function () {
      if (this.state.inputValue.length < 4) {
        $('.input-error').css('display', 'block');
        this.setState({
          disableBtnOk: true
        });
      } else {
        $('.input-error').css('display', 'none');
        this.setState({
          disableBtnOk: false
        });
      }
    });
  }

}

export default ModalConfirmController;