import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import DateTimeController from './DateTimePickerController';

const datePicker=new DateTimeController();

class DateTimePicker extends Component{
    constructor(props){
        super(props);
        this.state={
            idElement: props.pickerId,
        }
        this.onChangeDatePicker=datePicker.onChange.bind(this);
        this.onFocusDatePicker=datePicker.onFocusDatePicker.bind(this);   
    }

    render(){
        return(
            <Fragment>
                <p className='col-xs-6'>
                    <input   
                        placeholder='Выберите дату' 
                        name={this.state.idElement} 
                        id={this.state.idElement} 
                        autoComplete='off'
                        onFocus={this.onFocusDatePicker}
                        onChange={this.onChangeDatePicker}
                    />
                </p>
                <p className={'col-xs-12 error error-'+this.state.idElement}>* Введите дату формата 07-07-2020 11:11</p>
            </Fragment>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point);
    }
  })
)(DateTimePicker);
