import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore} from 'redux';
import reducer from '../../reducers/reducer';
import {Route, Switch, BrowserRouter} from 'react-router-dom';
import Home from '../Home';
import Logout from '../Logout';
import Main from '../../containers/Main/Main';

const store = createStore(reducer);
 
  class App extends Component {
    render() {
      return (
        <Provider
          store={store}>
          <BrowserRouter>
              <Switch>
                  <Route exact path="/" component={ Home }/>
                  <Route path="/main" component={Main} />
                  <Route path="/logout" component={Logout} />
              </Switch>         
          </BrowserRouter>
        </Provider>
      );
    }
  }
  
  export default App;
  