import React,{ Component} from 'react';
import './Header.css';

class Header extends Component{
    constructor(props){
        super(props);
        this.state={
            userName: this.props.user
        };
    }
   
    render() {
        return (
            <div className='wrap-navbar'>
                <div className='row navbar-inverse navbar-fixed-top '>
                    <div className='col-xs-12 col-sm-4'>
                        <h3>Километражник</h3>
                    </div> 
                    <div className='pull-right col-xs-6 col-sm-4'>
                        <a target='blank' href='/docs/Километражник2.0.pdf'>
                            <span className='glyphicon glyphicon-bookmark'></span>
                            <span>   FAQ</span>
                        </a>
                    </div>
                    <div className='pull-right col-xs-6 col-sm-4'>
                        <a className='logout' href='/logout'>
                            <span>Выйти ({this.props.user}) </span>
                            <span className='glyphicon glyphicon-log-out'></span>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}
export default Header;