export default function footRow(row=[], action){
    if(action.type==='ADD_FOOT_ROW'){
        return [
            ...row,
            action.footRow
        ];
    }
   else if(action.type==='DELETE_FOOT_ROW'){
        return row=[];
    }

    return row;
}