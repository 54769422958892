export default function errors(errors=[], action) {
    if(action.type==='SAVE_ERRORS'){
        if(errors.indexOf(action.index)===-1){
            errors.push(action.index);
        }
        return errors;
      }
    else if(action.type==='UPDATE_ERRORS'){
        if(errors.indexOf(action.index)!==-1){
            errors.splice(errors.indexOf(action.index), 1);
        }
        return errors;
    }
    else if(action.type==='SORT_ERRORS'){
        if(typeof action.newIndex !== 'undefined'){
            errors.forEach((item, i)=>{
                if(item===action.oldIndex){
                    errors.splice(i, 1, action.newIndex);
                }
                else if(item<=action.newIndex&&item>action.oldIndex){
                    errors.splice(i, 1, item-1);
                } 
                else if(item>=action.newIndex&&item<action.oldIndex){
                    errors.splice(i, 1, item+1);
                }  
            });
        }
        else{
            errors.forEach((item, i)=>{
                if(item===action.index){
                    errors.splice(i, 1, false);
                }
                else if(item>action.index){
                    errors.splice(i, 1, item-1);
                }
            });
        }
       
        return errors;
    }
    else if(action.type==='DELETE_ERRORS'){
        return errors=[];
    }
    return errors;
  }