export default function coordinates(coordinates = [], action) {
    if (action.type === 'SAVE_COORD') {
        if (action.index) {
            coordinates[action.index - 1] = action.coord;
            return coordinates;
        }
        return [
            ...coordinates,
            action.coord
        ]
    } else if (action.type === 'SORT_COORD') {
        return coordinates = action.sortCoord;
    } else if (action.type === 'UPDATE_COORD') {
        coordinates[action.index] = action.coord;
        return coordinates;
    } else if (action.type === 'DELETE_COORD') {
        
        return coordinates=action.coords;
    } else if (action.type === 'DELETE_COORDS') {
        return coordinates = [];
    }
    return coordinates;
}