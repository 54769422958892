import React, { Component, Fragment } from "react";
import {connect} from 'react-redux';
import { showTip, changeSelect, deletePoint, changeMapConf, onPlacesChanged, choiceZone, choicePogranPerehod, choiceAuto,  choiceCode, choicePostalCode} from './SearchBoxController';
import { updateMarker, updateSearchBox, cleanMap, drawLine, deleteMarker} from './../Map/MapController';
import './SearchBox.css';

const $=window.$;
const google=window.google;

class AddressSearchBox extends Component {
  constructor(props) {
    super(props);
    this.state={
      indexSearchBox: this.props.indexSearchBox,
      inputValue: (props.value)?props.value:'',
      indexSelectedOption:props.indexSelectedOption,
      secondValue:props.indexSelectedPostalCode,
      label: this.props.value,
      valueSelect: props.valueSelect
    }
  
    this.onPlacesChanged=onPlacesChanged.bind(this);
    this.deletePoint=deletePoint.bind(this);
    this.changeMapConf=changeMapConf.bind(this);
    this.changeSelect=changeSelect.bind(this);
    this.choiceZone=choiceZone.bind(this);
    this.choicePogranPerehod=choicePogranPerehod.bind(this);
    this.choiceCode=choiceCode.bind(this);
    this.choicePostalCode=choicePostalCode.bind(this);
    this.choiceAuto=choiceAuto.bind(this);
    this.showTip=showTip.bind(this);
    this.updateMarker=updateMarker.bind(this);
    this.updateSearchBox=updateSearchBox.bind(this);
    this.cleanMap=cleanMap.bind(this);
    this.drawLine=drawLine.bind(this);
    this.deleteMarker=deleteMarker.bind(this);
   
    this.getSecondLevelOfCodes=function() {
      if (!this.state.indexSelectedOption) {
        return null;
      }
      
      return (
        <Fragment>
          {this.getOptions(this.state.indexSelectedOption)}
        </Fragment>
      )
    }
    this.getOptions=function(index){
      if (! this.props.codes[index].postal_codes) {
        return null;
      }
      return  this.props.codes[index].postal_codes.map(function (el, i) {
        return <option key={i} value={i}>{el.postal_code}</option>
      });
    }
  }
  
  render() {  
    let wrapper, listZones, listPerehods, listCodes, listAuto;
     if(this.state.valueSelect==='geofenceSelect'){
      listZones = this.props.zones.map((item, index) => {
        if(this.state.indexSelectedOption===index){ 
          return <option key={index+1} selected value={index}>{item.name}</option>;
        }
        else{
          return <option key={index+1} value={index}>{item.name}</option>;
        }  
      });
      wrapper=<div className='wrap-searchInput' data='geofenceSelect'>
                <div>
                  <select className='selectZones'>
                      <option key={0}></option>
                      {listZones}
                  </select>
                </div>
                <p className='btn-select'>
                    Геозона
                </p>
              </div>
    }
    else if(this.state.valueSelect==='pogranSelect'){
      listPerehods = this.props.borders.map((item, index) => {
        if(this.state.indexSelectedOption===index){ 
          return <option key={index+1} selected value={index}>{item.name}</option>;
        }
        else{
          return <option key={index+1} value={index}>{item.name}</option>;
        }  
      });
      wrapper=<div className='wrap-searchInput' data='pogranSelect'>
                <div>
                  <select className='selectPerehod' >
                      <option value='' key={0}>Выберите погран. переход</option>
                      {listPerehods}
                  </select>
                </div>
                <p className='btn-select'>
                    Погран.переход
                </p>
              </div>
    }
    else if(this.state.valueSelect==='codeSelect'){
      listCodes=this.props.codes.map((code, index)=>{
        if(this.state.indexSelectedOption===index){
          return <option  selected  key={index+1} value={index}>{code.country_code}</option>;
        }
        else{
          return <option key={index+1} value={index}>{code.country_code}</option>;
        }
      });

      wrapper=<div className='wrap-searchInput' data='codeSelect'>
                <div>
                  <select onChange={this.choiceCode} value={this.state.indexSelectedOption} className='selectCodes'>
                    <option  key={0}></option>
                    {listCodes}
                  </select>
                  <select className='selectCodes selectPostalCodes' onChange={this.choicePostalCode} value={this.state.secondValue}>
                    <option value=""></option>
                    {this.getSecondLevelOfCodes()}
                  </select>
                </div>
                <p className='btn-select'>
                    Почт.код
                </p>
              </div>
    }
    else if(this.state.valueSelect==='carSelect'){
      listAuto=this.props.objects.map((item, index)=>{
        if(this.state.indexSelectedOption===index){ 
          return <option key={index+1} selected value={index}>{item.name}</option>;
        }
        else{
          return <option key={index+1} value={index}>{item.name}</option>;
        }  
      });
      wrapper=<div className='wrap-searchInput' data='carSelect'>  
                <div>
                  <select className='selectAuto' >
                      <option  key={0}></option>
                      {listAuto}
                  </select>
                </div>
                <p className='btn-select'>
                    Авто
                </p>
            </div>
    }
    else{
      wrapper=<div className='wrap-searchInput' data='mapSelect'>
                <div>
                  <input className="input-searchBox" value={this.state.inputValue} onChange={this.onPlacesChanged} />
                </div> 
                <p className='btn-select'>
                  Карта
                </p>
              </div>
    }

    return (
      <Fragment>
      <div className={'point'}>
          <div className='point-settings'>
                <p className={'name-point'}>{this.state.label}</p>
                <div className='wrap-span'> 
                    <span className={"glyphicon"}><i title="Точка на карте"  className="fa fa-map-marker" onClick={this.changeSelect} data='mapSelect'></i></span>
                    <span  className={"glyphicon"}><i title="Геозона" onClick={this.changeSelect} data='geofenceSelect' className="fa fa-globe"></i></span>
                    <span  className={"glyphicon"}><i title="Пограничные переходы" onClick={this.changeSelect} data='pogranSelect' className="fa fa-map-signs"></i></span>
                    <span className={"glyphicon"}><i className="fa fa-envelope"  title="Почтовый код" onClick={this.changeSelect} data='codeSelect'></i></span>
                    <span className='glyphicon'><i className="fa fa-truck" title='Автомобили' onClick={this.changeSelect} data='carSelect'></i></span>
                </div>
                <div className='wrap-span right'>
                    <span title="Удалить точку" className="glyphicon glyphicon-remove-circle remove" onClick={this.deletePoint}></span>
                    <span title="Показать точку на карте" className="glyphicon glyphicon-zoom-in glyphicon-marker-zoom" onClick={this.changeMapConf}></span>
                </div>    
          </div>
          <div className='one-point'>
              <p className='point-label'>{this.props.counterPoints}</p>      
              {wrapper}
        </div>
        <div className='error-input'>* Может произойти ошибка при построении маршрута, т.к. грузовым авто въезд в город может быть запрещен. При возникновении ошибки попробуйте изменить адрес</div>
      </div>
      </Fragment>
    );
  }

  componentDidMount(){
    const inputs=Array.prototype.slice.call($('.input-searchBox'));
    
    inputs.forEach((input, i)=>{
      const elem=$(input).parent().parent().parent().parent().parent();
      const parent=elem.parent();
      const index=[...parent.children()].indexOf([...elem][0]);
      const autocomplete = new google.maps.places.Autocomplete(input);
    
      google.maps.event.addListener(autocomplete, 'place_changed', function () {
        const place = autocomplete.getPlace();

        if (place.geometry) {
          const latLng = place.geometry.location; 
          self.updateMarker(index, latLng.lng(), latLng.lat(), place.formatted_address);
          self.updateSearchBox(index, place.formatted_address, null, 'mapSelect');
          self.cleanMap();     
          self.props.onChange({type:'UPDATE_COORD', index: index, coord: new google.maps.LatLng({lng:latLng.lng(), lat:latLng.lat()})});
          if(place.types.indexOf('locality')!==-1&&$("input[name='truck']").is(':checked')){
            self.showTip(index);
            self.props.onChange({type:'SAVE_ERRORS', index: index});
          }
          else if(place.types.indexOf('locality')!==-1&&!$("input[name='truck']").is(':checked')){
            self.props.onChange({type:'SAVE_ERRORS', index: index});
          }
          else {
            self.props.onChange({type:'UPDATE_ERRORS', index: index});
          }

          self.drawLine();
        }
      });
    });

    const self=this;
    const selects=['mapSelect', 'geofenceSelect', 'pogranSelect', 'codeSelect', 'carSelect'];
    self.props.searchBoxes.forEach((box, i)=>{
      selects.forEach(select=>{
        if(select===box.props.valueSelect){
          $(Array.prototype.slice.call( $('span[data="'+select+'"]'))[i]).addClass('activeBtnSelect');
          $(Array.prototype.slice.call( $('i[data="'+select+'"]'))[i]).addClass('activeBtnSelect');
        }
        else{
          $(Array.prototype.slice.call( $('span[data="'+select+'"]'))[i]).removeClass('activeBtnSelect');
          $(Array.prototype.slice.call( $('i[data="'+select+'"]'))[i]).removeClass('activeBtnSelect');
        }
      }); 
    });

      $(".selectPerehod").select2({
        placeholder: "Выберите погран. переход",
        allowClear: true
        } );    
        $('.selectPerehod').on('select2:selecting', function(e) {
          self.choicePogranPerehod(e);
        });

        $(".selectZones").select2({
          placeholder: "Выберите геозону",
          allowClear: true
        });    
        $('.selectZones').on('select2:selecting', function(e) {
            self.choiceZone(e);
        });

        $(".selectAuto").select2({
          placeholder: "Выберите объект",
          allowClear: true
        });    
        $('.selectAuto').on('select2:selecting', function(e) {
            self.choiceAuto(e);    
          });  
        //inputs[inputs.length-1].focus();    
    }
}

export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point)
    }
  })
  )(AddressSearchBox);
  