export default function settings(settings = {}, action) {
    if (action.type === 'SAVE_SETTINGS') {
        settings[action.index]=action.item;
    }
    else if (action.type === 'DELETE_SETTINGS') {
        for(let i in settings){
          if(+i===action.index){
            delete settings[i];
          }
          else if(+i>action.index){
            settings[+i-1]=settings[i];
            delete settings[i];
          }
        }
        return settings;
      }
    else if(action.type==='DELETE_ALL_SETTINGS'){
      if(action.activeTrack){
        settings={};
        settings['1']=action.settings;
        return settings;
      }
      else{
        return settings={};
      }
    }
    return settings;
}