export default function markers(points = [], action) {
  if (action.type === 'ADD_POINT') {
    if (action.index) {
      points[action.index - 1] = action.newMarker;
      return points;
    } else {
      return [
        ...points,
        action.newMarker
      ]
    }
  } else if (action.type === 'SORT_POINT') {
    return points = action.sortPoints;
  } else if (action.type === 'DELETE_POINT') {
    if (action.markers) {
      return points = action.markers;
    } else if (action.index) {
      return points;
    } else if (action.counter) {
      points.forEach((item, index) => {
        if (item === action.counter || item.label === action.counter) {
          points.splice(index, 1);
          return points;
        }
      })
    }
  } else if (action.type === 'CHANGE_POINT') {
    points.splice(action.index, 1, action.newMarker);
    return points;
  } else if (action.type === 'DELETE_ALL_POINTS') {
    return points = [];
  }
 
  return points;
}