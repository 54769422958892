import React, {
    Component, Fragment
  } from 'react';
import WialonRoutes from './../WialonRoutes/WialonRoutes';
import TruckSettings from '../TruckSettings/TruckSettings';
import MainSettings from '../MainSettings/MainSettings';
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import arrayMove from 'array-move';
import  {addPoint, createRoute, cleanDescriptionTable, cleanMainTable, clean} from './SettingsController';
import {cleanMap, drawLine, dragMarker, deletePoints} from '../Map/MapController';
import {connect} from 'react-redux';
import './Settings.css';

const $=window.$;
const SortableItem = SortableElement(({value}) => <li>{value}</li>);
const SortableList = SortableContainer(({items}) => {
  return (
    <ul>
      {items.map((value, index) => (
        <SortableItem key={`item-${index}`} index={index} value={value} />
      ))}
    </ul>
  );
});

class Settings extends Component {
    constructor(props){
        super(props);   
        this.createRoute=createRoute.bind(this);
        this.addPoint=addPoint.bind(this);
        this.deletePoints=deletePoints.bind(this);
        this.cleanMap=cleanMap.bind(this);
        this.cleanMainTable=cleanMainTable.bind(this);
        this.cleanDescriptionTable=cleanDescriptionTable.bind(this);
        this.drawLine=drawLine.bind(this);
        //this.clickMarker=clickMarker.bind(this); import from MapCont-r
        this.dragMarker=dragMarker.bind(this);
        this.clean=clean.bind(this);
    }

    onSortEnd = ({oldIndex, newIndex}) => { 
        this.cleanMap();
        this.props.onChange({type: 'SORT_ERRORS', oldIndex: oldIndex, newIndex: newIndex});
        this.props.onChange({type: 'SORT_SearchBox', sortSearchBoxes: arrayMove(this.props.searchBoxes, oldIndex, newIndex)});
        this.props.onChange({type: 'SORT_POINT', sortPoints: arrayMove(this.props.markers, oldIndex, newIndex)});
        this.props.onChange({type: 'SORT_COORD', sortCoord: arrayMove(this.props.coordinates, oldIndex, newIndex)});

        let markers=this.props.markers;
        let boxes=this.props.searchBoxes;  
        boxes.forEach((box, index)=>{
            if(box.props.indexSelectedPostalCode){
                const countryCode=boxes[index].props.indexSelectedPostalCode.split('-')[0];
                const postalCode=boxes[index].props.indexSelectedPostalCode.split('-')[1];
                const codes=this.props.codes;
                codes.forEach((code, i)=>{
                  if(code.country_code===countryCode){
                    code.postal_codes.forEach((postCode, j)=>{
                      if(postalCode===postCode.postal_code){
                        $(Array.prototype.slice.call($('.selectPostalCodes'))[index]).append('<option selected value="'+codes[i].country_code+'-'+postCode.postal_code+'">'+postCode.postal_code+'</option>');
                      }
                      else{
                        $(Array.prototype.slice.call($('.selectPostalCodes'))[index]).append('<option  value="'+codes[i].country_code+'-'+postCode.postal_code+'">'+postCode.postal_code+'</option>');
                      }
                    });
                  }
                });
            }
        });
      
        this.drawLine();
        boxes.forEach((item, index)=>{
            document.querySelectorAll('.point-label')[index].textContent=index+1;
            if(typeof(markers[index])!=='number'){
               markers[index].setLabel(index+1+'');
            }
        });  
       
        this.props.errors.forEach((item, i)=>{
          if(item!==false&&$("input[name='truck']").is(':checked')){
            $($('.error-input')[item]).css('display', 'block');
            $($('.wrap-searchInput')[item]).css('color', 'darkBlue');
          }
        });
        
      };
   
    shouldCancelStart(e) {
        if (e.target.className.indexOf('fa')!==-1||e.target.className.indexOf('glyphicon')!==-1||e.target.localName==='input'||e.target.localName==='select'||e.target.className.indexOf('select2')!==-1) {
            return true; // Return true to cancel sorting
        }
    }

    componentDidMount(){
        $('.clean-points').attr('disabled', true);
    }

    render() {
        return (  
            <Fragment>     
                <div className='wrapper-settings'>
                    <WialonRoutes 
                        counterPoints={this.props.counterPoints}
                        onChangeCounterPoints={this.props.onChangeCounterPoints} 
                        resetModalWindow={this.props.resetModalWindow}
                    >
                    </WialonRoutes>
                    <TruckSettings 
                        resetModalWindow={this.props.resetModalWindow}/>
                    <MainSettings />
                    <div className='wrap-points'>   
                        <SortableList items={ this.props.searchBoxes}  onSortEnd={this.onSortEnd} shouldCancelStart={this.shouldCancelStart}/>
                    </div>
                    <div className='wrap-btn-sett'>
                        <button
                            className='btn btn-default' 
                            data-toggle="tooltip" 
                            data-placement="bottom" 
                            title="Добавить точку" 
                            onClick={this.addPoint} >
                                Добавить точку
                        </button>
                        <button 
                            className='btn btn-default clean-points' 
                            data-toggle="tooltip" 
                            data-placement="bottom" 
                            title="Удалить все точки"  
                            onClick={this.clean}>
                                Очистить все точки
                        </button>
                        <button 
                        
                            className='btn btn-default build-route' 
                            onClick={this.createRoute}>
                                Построить маршрут
                        </button>
                    </div>
                </div>    
            </Fragment>        
        );
    }
  }
  
export default connect(
    state => state,
    dispatch => ({
      onChange: (searchBox) => {
      dispatch(searchBox)
    }
  })
)(Settings);
  

  