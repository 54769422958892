import React from 'react';
import Modal from 'react-modal';
import ModalConfirmController from './ModalConfirmController';

const modalController=new ModalConfirmController();
const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    padding               : '0',
    maxWidth              : '450px',
    minWidth              : '250px'
  }
};

class ConfirmWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state={
        open: this.props.openConfirm  
    };
   
   this.closeModal=modalController.closeModal.bind(this);
   this.deleteRoute=modalController.deleteRoute.bind(this);
   this.afterOpenModal=modalController.afterOpenModal.bind(this);
  }
 
  render() {
    return (
      <Modal
          isOpen={this.props.open}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
      >
          <div className="modal-window row" >
              <div 
                  className='confirm-title col-xs-12' 
                  ref={subtitle => this.subtitle = subtitle}>
                      <span 
                          className='glyphicon glyphicon-info-sign'>
                      </span>
                      <span 
                          className='modal-title'> {this.props.title}
                      </span> 
                      <span 
                          onClick={this.closeModal} 
                          className='glyphicon glyphicon-remove-circle'>
                      </span>
              </div>
              <div 
                  className='modal-text col-xs-12'>
                      <p >{this.props.question}</p>
              </div>
              <div 
                  className='col-xs-12 row modal-buttons'>
                      <div className='center col-xs-6'>
                          <input 
                              type='button'  
                              value='Нет' 
                              onClick={this.closeModal}/>
                      </div>
                      <div className='center col-xs-6'>
                          <input 
                              type='button' 
                              value='Да' 
                              onClick={this.deleteRoute}/>
                      </div>
              </div>     
          </div>
      </Modal>
    );
  }
}
Modal.setAppElement('body');    //Для избежания ошибки!!! Warning: react-modal: App element is not defined. Please use `Modal.setAppElement                                     (el)` or set `appElement={el}`.

export default ConfirmWindow;