const $ = window.$;

class MainController {

  onChangeCounterPoints(arg) {
  
    if (arg === 0) {
      this.setState({
        counterPoints: 0
      });
      $('.clean-points').attr('disabled', true);
    } else if (arg > 0) {
      this.setState({
        counterPoints: arg
      });
    } else {
      this.setState({
          counterPoints: this.state.counterPoints + 1
        },
        function () {
          if (this.state.counterPoints >= 1) {
            $('.clean-points').attr('disabled', false);
          }
        });
    }
  }

  resetModalWindow(message, title) {
    setTimeout(() => this.setState({
      modalText: message,
      modalTitle: title,
      visibleModal: !this.state.visibleModal
    }), 500);
  }

  closeModal(stateModal) {
    setTimeout(() => this.setState({
      visibleModal: stateModal
    }), 300);
  }

  hideBlocks(elem) {
    elem.hide(250, function () {
      elem.html('');
    })
  }

  showBlocks(elem) {
    elem.show(250);
  }

}

export default MainController;