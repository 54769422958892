import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import CountryDescriptionController from './CountryDescriptionController'; 

const CountriesDescCtrl=new CountryDescriptionController();

class CountryDescription extends Component{
    
    constructor(props){
        super(props);
        this.showDescription=CountriesDescCtrl.showDescription.bind(this);
    }

    render(){        
        const listCountries=this.props.countriesDescription.map((element, i) => {
            return <tr key={i}><td>{i+1}</td><td>{element.country}</td><td>{element.systems.join(' / ')}</td><td>{element.amountInTargetCurrency}</td></tr>;
        });
        
        return (
            <Fragment>
                <div onClick={this.showDescription}>
                    <span>Информация по платным дорогам по странам </span>
                    <span className='right glyphicon glyphicon-chevron-down'></span>
                </div>
                        <table className='country-table'> 
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Страна</th>
                                    <th>Системы</th>
                                    <th>Стоимость, EUR</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listCountries}
                            </tbody>   
                            <tfoot>
                                <tr>
                                    <th colSpan={3}>Итого:</th>
                                    <th>{this.props.totalCost}</th>
                                </tr>
                            </tfoot>
                        </table>
            </Fragment>
        );
    }
}

export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point);
    }
  })
  )(CountryDescription);