const $ = window.$;
const wialon = window.wialon;
const google = window.google;

function choiceCode(event) {
  const index = event.target.value;
  this.setState({indexSelectedOption: index});
}

function choiceAuto(event) {
  const self = this;
  const value = +event.params.args.data.id;
  const objects = this.props.objects;
  const index=$(event.target).parent().parent().parent().parent().parent().index();
  const positionItem = wialon.core.Session.getInstance().getItem(objects[value].id).getPosition();
  
  wialon.util.Gis.getLocations([{
    lon: positionItem.x,
    lat: positionItem.y
  }], function (code, address) {
    self.updateMarker(index, positionItem.x, positionItem.y, address[0]);
    self.updateSearchBox(index, address[0], value, 'carSelect');
    self.cleanMap();
    self.props.onChange({
      type: 'UPDATE_COORD',
      index: index,
      coord: new google.maps.LatLng({
        lng: positionItem.x,
        lat: positionItem.y
      })
    });
    self.drawLine();
  });
}

function choiceZone(event) {
  const self = this;
  const value = +event.params.args.data.id;
  const zones = self.props.zones;
  const index=$(event.target).parent().parent().parent().parent().parent().index();
  wialon.util.Gis.getLocations([{
    lon: zones[value].lng,
    lat: zones[value].lat
  }], function (code, address) {
    self.updateMarker(index, zones[value].lng, zones[value].lat, address[0]);
    self.updateSearchBox(index, address[0], value, 'geofenceSelect');
    self.cleanMap();
    self.props.onChange({
      type: 'UPDATE_COORD',
      index: index,
      coord: new google.maps.LatLng({
        lng: zones[value].lng,
        lat: zones[value].lat
      })
    });
    self.drawLine();
  });
}

function choicePogranPerehod(event) {
  const self = this;
  const value = +event.params.args.data.id;
  const borders = self.props.borders;
  const index=$(event.target).parent().parent().parent().parent().parent().index();
  
  wialon.util.Gis.getLocations([{
    lon: +borders[value].lat,
    lat: +borders[value].lng
  }], function (code, address) {
    self.updateMarker(index, +borders[value].lat, +borders[value].lng, address[0]);
    self.updateSearchBox(index, address[0], value, 'pogranSelect');
    self.cleanMap();
    self.props.onChange({
      type: 'UPDATE_COORD',
      index: index,
      coord: new google.maps.LatLng({
        lng: +borders[value].lat,
        lat: +borders[value].lng
      })
    });
    self.drawLine();
  });
}

function choicePostalCode(event) { 
  const self = this;
  const value = event.target.value;
  const codes = self.props.codes;
  const index=$(event.target).parent().parent().parent().parent().parent().index();
  self.setState({
    secondValue: value
  });
    
      wialon.util.Gis.getLocations([{
        lon: codes[self.state.indexSelectedOption]['postal_codes'][value].lat,
        lat: codes[self.state.indexSelectedOption]['postal_codes'][value].lng
      }], function (code, address) {
        self.updateMarker(index, +codes[self.state.indexSelectedOption]['postal_codes'][value].lat, +codes[self.state.indexSelectedOption]['postal_codes'][value].lng, address[0]);
        self.updateSearchBox(index, address[0], self.state.indexSelectedOption, 'codeSelect', value);
        self.cleanMap();
        self.props.onChange({
          type: 'UPDATE_COORD',
          index: index,
          coord: new google.maps.LatLng({
            lng: +codes[self.state.indexSelectedOption]['postal_codes'][value].lat,
            lat: +codes[self.state.indexSelectedOption]['postal_codes'][value].lng
          })
        });
        self.drawLine();
      });
     
}

function changeSelect(event) {
  const index=$(event.target).parent().parent().parent().parent().parent().index();
  const self = this;
  const target = $(event.target);

  self.props.onChange({type:'UPDATE_ERRORS', index: index});
  self.updateSearchBox(index, null, null,  target.attr('data'));
  self.deleteMarker(index);
  self.cleanMap();
}

function changeMapConf(event) {
  const map = this.props.map;
  let markers = this.props.markers;
  const btnRemove = Array.prototype.slice.call($('.glyphicon-marker-zoom'));
  btnRemove.forEach((item, index) => {
    if (item === event.target && typeof (markers[index] !== 'number')) {
      map.setCenter({
        lat: markers[index].getPosition().lat(),
        lng: markers[index].getPosition().lng()
      });
      map.setZoom(10);
    }
  });
}

function onPlacesChanged(event) {
  this.setState({
    inputValue: event.target.value
  });
};

function deletePoint(event) {
  const self=this;
  let markers = this.props.markers;
  let searchBoxes = this.props.searchBoxes;
  let coordinates = this.props.coordinates;
  const index=$(event.target).parent().parent().parent().parent().index();

  if (typeof (markers[index]) !== 'number') {
    markers[index].setMap(null);
  }
  markers.splice(index, 1);
  markers.forEach((marker, i) => {
    if (typeof (marker) !== 'number') {
      marker.setLabel(i + 1 + '');
    }
  });

  coordinates.splice(index, 1);
  searchBoxes.splice(index, 1);
  
  this.props.onChange({
    type: 'SORT_POINT',
    sortPoints: markers
  });
  this.props.onChange({
    type: 'SORT_SearchBox',
    sortSearchBoxes: searchBoxes
  });
  this.props.onChange({
    type: 'SORT_COORD',
    sortCoord: coordinates
  });

  this.props.onChange({type:'SORT_ERRORS', index: index});

  setTimeout(function () {
    document.querySelectorAll('.point-label').forEach((point, i) => {
      point.textContent = i + 1;
    });
    
    self.props.errors.forEach((item, i)=>{
      if(item!==false){
        $($('.error-input')[item]).css('display', 'block');
        $($('.wrap-searchInput')[item]).css('color', 'darkBlue');
      }
    });
  }, 100);

  this.props.onChangeCounterPoints(markers.length);
  this.cleanMap();
  this.drawLine();
  $('.wrap-description').hide(250, function () {
    $('.wrap-description').css('right', -500);
  });
}

function showTip(index){  
  $($('.error-input')[index]).css('display', 'block');
  $($('.wrap-searchInput')[index]).css('color', 'darkBlue');
}

export {
  changeMapConf,
  onPlacesChanged,
  deletePoint,
  changeSelect,
  choiceZone,
  choiceCode,
  choiceAuto,
  choicePostalCode,
  choicePogranPerehod, 
  showTip
}