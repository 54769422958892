const $ = window.$;
class MainSettingsController {

    handleTollOrFreeRoads(event) {
        const checked = event.target.checked;
        if(checked){
            $('.settings-cog').css('display', 'block');
            $('.accordeon-with-exceptions').css('maxHeight', 1200);
            $('.accordeon-with-exceptions').css('visibility', 'visible');
            $('.accordeon-with-exceptions').css('display', 'block');
        }
        else{
            $('.settings-cog').css('display', 'none');
            $('.accordeon-with-exceptions').css('maxHeight', 0);
            $('.accordeon-with-exceptions').css('visibility', 'hidden');
            $('.accordeon-with-exceptions').css('display', 'none');
        }
    }

    onChangeFuel(event) {
        const cellsWithFuelCost = $('.result-table tbody .cell-fuelCost');
        const cellsWithAdditCosts = $('.result-table tbody .cell-additCosts');
        const cellsWithDistance = $('.result-table tbody .cell-distance');
        const checkDiffValueOfFuel = $('.checkFuelConsumption');
        const value = event.target.value.replace(' /\\D/g', '');
        const cell=this.props.bodyRows;
        const name = event.target.name;
        let totalFuel = 0;
        let totalCosts=0;
        let limit, fuel;
        let newState = {};

        switch (name) {
            case 'fuelConsumption':
                limit = 50;
                break;
            case 'fuelCost':
                limit = 5;
                break;
            case 'additCosts':
                limit = 100;
                break;
            default:
                break;
        }

        if (+event.target.value <= limit) {
            newState[name] = value;
            this.setState(
                newState
            );
            $('.error-' + name).css('display', 'none');
            if (name === 'fuelConsumption') {
                if(this.props.tabs.length>0){
                    const allRoutes=this.props.allRoutes;
                    for(let key in allRoutes){
                        const bodyRows=allRoutes[key]['info']['bodyRows'];
                        this.changingFuelConsumption(key, value, bodyRows);
                    }    
                }

                for (let i = 0; i < cell.length; i++) {
                    fuel = parseInt(cell[i]['distance']) / 100 * (+value);
                    cellsWithFuelCost[i].textContent = fuel.toFixed(2) + ' л. / ' + (fuel * $('#fuelCost').val()).toFixed(2) + ' EUR';
                    totalFuel = totalFuel + fuel;
                }
                $('.result-table tfoot .cell-fuelCost').text('Расходы на топливо: ' + totalFuel.toFixed(2) + ' л. / ' + (totalFuel * $('#fuelCost').val()).toFixed(2) + ' EUR');
            } 
            else if(name==='fuelCost'){
                if(this.props.tabs.length>0){
                    const allRoutes=this.props.allRoutes;
                    for(let key in allRoutes){
                        const bodyRows=allRoutes[key]['info']['bodyRows'];
                        this.changingFuelCost(key, value, bodyRows);
                    }    
                }
                let fuelConsumption;
                for (let j = 0; j < cellsWithFuelCost.length; j++) {
                    if ($(checkDiffValueOfFuel[j]).val()) {
                        fuelConsumption = $(checkDiffValueOfFuel[j]).val();
                    } else {
                        fuelConsumption = this.state.fuelConsumption;
                    }
                    fuel = parseInt(cellsWithDistance[j].textContent) / 100 * fuelConsumption;
                    cellsWithFuelCost[j].textContent = fuel.toFixed(2) + ' л. / ' + (fuel * value).toFixed(2) + ' EUR';
                    totalFuel = totalFuel + fuel;
                }
               
                $('.result-table tfoot .cell-fuelCost').text('Расходы на топливо: ' + totalFuel.toFixed(2) + ' л. / ' + (totalFuel * value).toFixed(2) + ' EUR');
            }
            else{
                if(this.props.tabs.length>0){
                    const allRoutes=this.props.allRoutes;
                    for(let key in allRoutes){
                        const bodyRows=allRoutes[key]['info']['bodyRows'];
                        this.changingTable(key, value, bodyRows);
                    }    
                }

                for (let j = 0; j < cell.length; j++) {
                    let additCosts=cell[j]['distance']* value;
                    cellsWithAdditCosts[j].textContent = additCosts;
                    totalCosts = totalCosts + additCosts;
                }
               
                $('.result-table tfoot .cell-additCosts').text('Доп. расходы, EUR: ' + totalCosts);
            }                   

            
            
        } else {
            $('.error-' + name).css('display', 'block');
        }
    }

    changingTable(key, value, cell){
        let totalCosts=0;  
        for (let j = 0; j < cell.length; j++) {
            let additCosts=cell[j]['distance']* value;
            cell[j]['additCosts']=additCosts;
            totalCosts = totalCosts + additCosts;
        }
       let footRow=this.props.allRoutes[key]['info']['footRows'][0];
       footRow['additCosts']=totalCosts;
       
       this.props.onChange({type:'CHANGE_TRACK', index: key, changingItem: 'bodyRows', value: cell});
       this.props.onChange({type:'CHANGE_TRACK', index: key, changingItem: 'footRows', value:  [footRow]});
    }

    changingFuelConsumption(key, value, cell){
        let totalFuel=0;   
        
        for (let j = 0; j < cell.length; j++) {
            let fuel = parseInt(cell[j]['distance']) / 100 * (+value);
            cell[j]['fuelCost']=fuel.toFixed(2) + ' л. / ' + (fuel * $('#fuelCost').val()).toFixed(2) + ' EUR';;
            totalFuel = totalFuel + fuel;
        }
       let footRow=this.props.allRoutes[key]['info']['footRows'][0];
       footRow['fuelCost']=totalFuel.toFixed(2) + ' л. / ' + (totalFuel * $('#fuelCost').val()).toFixed(2) + ' EUR';
       this.props.onChange({type:'CHANGE_TRACK', index: key, changingItem: 'bodyRows', value: cell});
       this.props.onChange({type:'CHANGE_TRACK', index: key, changingItem: 'footRows', value:  [footRow]});
    }

    changingFuelCost(key, value, cell){
        let fuelConsumption;
        let totalFuel=0;   
        
        for (let j = 0; j < cell.length; j++) {
            if ($($('.checkFuelConsumption')[j]).val()) {
                fuelConsumption = $($('.checkFuelConsumption')[j]).val();
            } else {
                fuelConsumption = this.state.fuelConsumption;
            }
            let fuel = parseInt(cell[j]['distance']) / 100 * fuelConsumption;
            cell[j]['fuelCost'] = fuel.toFixed(2) + ' л. / ' + (fuel * value).toFixed(2) + ' EUR';
            totalFuel = totalFuel + fuel;
        }
       let footRow=this.props.allRoutes[key]['info']['footRows'][0];
       footRow['fuelCost']=totalFuel.toFixed(2) + ' л. / ' + (totalFuel * value).toFixed(2) + ' EUR';
       this.props.onChange({type:'CHANGE_TRACK', index: key, changingItem: 'bodyRows', value: cell});
       this.props.onChange({type:'CHANGE_TRACK', index: key, changingItem: 'footRows', value:  [footRow]});
  
    }

    checkDiffValueOfFuel() {
        this.setState({
            checkFuel: !this.state.checkFuel
        }, function () {
            if (this.state.checkFuel) {
                $('#fuelConsumption').attr('readonly', true);
                $('#fuelConsumption').css('cursor', 'not-allowed');
            } else {
                $('#fuelConsumption').attr('readonly', false);
                $('#fuelConsumption').css('cursor', 'text');
            }
        });
    }

    showSettings() {
        const body = $('.accordeon-with-exceptions');
        if (body.css('display')==='none') {
            body.css('visibility', 'visible');
            body.css('maxHeight', '1200px');
            body.css('display', 'block');
        } else {
            body.css('maxHeight', 0);
            body.css('visibility', 'hidden');
            body.css('display', 'none');
        }
    }
}

export default MainSettingsController;