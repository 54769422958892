import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {drawAdditRoutes, drawAnimateCircle} from '../Settings/SettingsController';
import AdditPanelController from './AdditPanelController'; 
import './AdditPanel.css';
import ConfirmWindowWithText from '../../components/ModalConfirm/ModalConfirmWithTextInput';

const AdditPanelCtrl=new AdditPanelController();

class AdditPanel extends Component{
    constructor(props){
        super(props);
        this.state={
            openConfirmGeo: false,
            tabs:[]
        }
        this.addTab=AdditPanelCtrl.addTab.bind(this);
        this.activatingTab=AdditPanelCtrl.activatingTab.bind(this);
        this.cleanAllRoutes=AdditPanelCtrl.cleanAllRoutes.bind(this);
        this.updateRoutes=AdditPanelCtrl.updateRoutes.bind(this);
        this.updateTableAndBoxes=AdditPanelCtrl.updateTableAndBoxes.bind(this);
        this.updateSettings=AdditPanelCtrl.updateSettings.bind(this);
        this.savePDF=AdditPanelCtrl.savePDF.bind(this);
        this.saveXLSXFile=AdditPanelCtrl.saveXLSXFile.bind(this); 
        this.drawAdditRoutes=drawAdditRoutes.bind(this);
        this.drawAnimateCircle=drawAnimateCircle.bind(this);
        this.saveGeofence=AdditPanelCtrl.saveGeofence.bind(this);
        this.showDescription=AdditPanelCtrl.showDescription.bind(this);
        this.changeStatusWindowGeo=AdditPanelCtrl.changeStatusWindowGeo.bind(this);
        this.submitGeo=AdditPanelCtrl.submitGeo.bind(this);
    }

    render(){ 
        const list=this.props.tabs.map((element, i)=>{ 
            return (
                <li key={i} role="presentation"  onClick={this.activatingTab}><a>{element}</a></li>
            );   
        });
        return(
            <Fragment>
                <ul className="nav nav-tabs">
                    {list}
                </ul>   
                <div className='wrap-btn-save' >
                    <span title='Добавить маршрут в сравнение'>Добавить в сравнение 
                        <input type='checkbox' name='newTab' onChange={this.addTab}></input>
                    </span>
                    <p onClick={this.savePDF} title='Сохранить информацию о маршруте в формате pdf'>PDF
                        <span className='glyphicon glyphicon-download'></span> 
                    </p>
                    <p onClick={this.showDescription} title='Показать описание маршрута'>
                        <span className='glyphicon glyphicon-list-alt'></span> 
                    </p>
                    <p onClick={this.saveXLSXFile} title='Сохранить Excel-файл'>Excel
                        <span className='glyphicon glyphicon-save-file' ></span> 
                    </p>
                    <p onClick={this.saveGeofence} title='Сохранить маршрут как геозону в системе Wialon.'>
                        <img alt='icon' src='/images/icons/saveGeo.png'/>
                    </p>
                    <button id='btn-delete-allRoutes' onClick={this.cleanAllRoutes} title='Удалить все неактивные маршруты.'>Удалить маршруты</button>
                </div>
                <ConfirmWindowWithText
                    open={this.state.openConfirmGeo}
                    title='Сохранение геозоны'
                    question='Введите название геозоны для сохранения в системе Wialon.'
                    changeStatusWindow={this.changeStatusWindowGeo} 
                    saveChanges={this.submitGeo}>
                </ConfirmWindowWithText>
            </Fragment>
        );
    }
}
export default connect(
    state => state,
    dispatch => ({
        onChange: (point) => {
        dispatch(point);
        }
    })
)(AdditPanel);
