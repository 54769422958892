import React from 'react';
import axios from 'axios';
import AddressSearchBox from '../Search/SearchBox';

const convertTime = window.convertTime;
const convertTimeESTR = window.convertTimeESTR;
const dateOfReturn = window.dateOfReturn;
const $ = window.$;
const google = window.google;

function createRoute() {
  
  const self=this;
  let counter=0;
  let allPoints=[];

  //clean old data
  this.cleanMap();
  this.cleanMainTable();
  this.cleanDescriptionTable();
  
  const coordinates = self.props.coordinates;
  const markers = self.props.markers;
  const boxes=self.props.searchBoxes;
  let newBox;
  let sortBoxes=[];

  markers.forEach((marker, index)=>{
    if(typeof(marker)!=='number'&&(boxes[index].props.selectSearch&&(!boxes[index].props.value||boxes[index].props.value!==marker.getTitle()))){
        newBox=<AddressSearchBox map = {
            this.props.map
        }
        key = {
            marker.getPosition().lat()
        }
        valueSelect='mapSelect'
        counterPoints = {
            marker.getLabel()
        }
        onChangeCounterPoints = {
            this.props.onChangeCounterPoints
        }
        value = {
            marker.getTitle()
        }></AddressSearchBox>
    }
    else {
        newBox=boxes[index];
    }
    sortBoxes.push(newBox);
});

  if (coordinates.length <2) {
    self.props.resetModalWindow('Задайте минимум две контрольные точки.', 'Внимание');
  } 
  else {
    preloader('start');
    routing();
  }

async function routing() {
  //define request params
  const stateTollRoads=$("input[name='tollRoads']").is(':checked');
  const mode=$("input[name='mode']:checked").val();
  const exception=$("input[name='exceptions']:checked").val();
  const truckChecked=$("input[name='truck']").is(':checked');
  let waypoints='';
  let coords=[];
  let truckParams, auto;

  for (let i = 0; i < markers.length; i++) {
    if (typeof (markers[i]) !== 'number') {
      coords.push({
        lat:markers[i].getPosition().lat(),
        lng:markers[i].getPosition().lng(),
        label: markers[i].getTitle()
      });
      waypoints=waypoints+'waypoint'+counter+'='+markers[i].getPosition().lat()+','+markers[i].getPosition().lng()+'&';
      counter++;
      allPoints.push(markers[i]);
    }
  }

  if (truckChecked) {
    truckParams=gettingTruckParams();
    auto = 'truck';
   } else {
     auto = 'car';
   }
  
  await axios.post('/getRoute', {
    points: waypoints,
    coords: coords,
    car: auto,
    mode: mode,
    tollRoads: (stateTollRoads===true)?true:false,
    exception: exception,
    autoParams: truckParams
  })
    .then(function (response) {
        const checkedMode=$('input[name=mode]:checked').val();
        const dictionary={'fastest':'Быстрый', 'shortest': 'Короткий', 'balanced': 'Оптимальный', 'truck': 'грузового автомобиля', 'car': 'легкового автомобиля'};
        
        if(response.data.error){
          self.props.resetModalWindow(dictionary[checkedMode]+' маршрут для '+dictionary[auto]+' не найден. '+response.data.error, 'Внимание', true);
        }
      if(response.data||response.data.result) {
        
        if($('input[name="newTab"]').is(':checked')){
          const tabs=self.props.tabs;
         $('input[name="newTab"]').prop('disabled', false);
        
          self.props.onChange({type: 'ADD_TAB', newItem: tabs.length+1});
        }
        if(self.props.tabs.length<2){
          $('#btn-delete-allRoutes').prop('disabled', true);
        }
        else{
          $('#btn-delete-allRoutes').prop('disabled', false);
        }

        let data=(response.data.result)?response.data.result:response.data;
        for(let key in data.coordRoads){
         self.props.onChange({type:'SAVE_COORDS_OF_TRACK', key: key, coords:data.coordRoads[key]});
          
          drawMainRoute(self, data.coordRoads[key], key, auto);
        }

        drawAnimateCircle(data.coordinates, self);
        if(self.props.tabs.length>0){
          $('.nav-tabs li').removeClass('active'); 
          drawAdditRoutes(self);
        }
      
        self.props.onChange({type:'SAVE_MIN_MAX', minMax:{minLat: data.latMin, maxLat: data.latMax, minLng: data.lngMin, maxLng: data.lngMax}});
        self.props.onChange({type: 'SAVE_ALL_COORDS', coords: data.coordinates}); 
        
        $('.wrap-btn-save').css('display', 'flex');
        $('.wrap-result-table').css('display', 'block');
        $('.nav-tabs').css('display', 'block');
        $('.wrap-result-table').css('maxHeight', '50%');
        $('#wrapper-map').css('height', '50%');

      const inputCheckFuel=$("input[name='checkFuel']").prop('checked');
      self.props.onChange({type: 'SAVE_COST', totalCost: data.totalCost});        
      self.props.onChange({type:'SAVE_COUNTRY_DESCRIPTION', totalCostsByCountry: data.totalCostsByCountry });
      let rows=[];
          data.points.forEach((item, i)=>{
        
        //paste row in body
        const distance=(item['length']/1000).toFixed(2);
        const time=convertTime(item['travelTime']);
        const fuel=distance/100*$('#fuelConsumption').val();
        const timeEstr=convertTime(convertTimeESTR(item['travelTime'])*3600);

        const bodyRow={
          point0: allPoints[i].getTitle(),
          point1: allPoints[i+1].getTitle(),
          distance: distance,
          time: time,
          timeEstr: timeEstr,
          fuelCost: fuel.toFixed(2)+" л. / "+(fuel*$('#fuelCost').val()).toFixed(2)+" EUR",
          additCosts: distance*$('#additCosts').val()
        }
        
        if(inputCheckFuel){
            bodyRow.checkFuelValue=true;
            bodyRow.totalCost=false;
        }
        else {
            bodyRow.checkFuelValue=false;
            bodyRow.totalCost=false;
        }   

        rows.push(bodyRow);
       });
     
       //paste row in footer 

      const totalDistance=(data['totalDistance']/1000).toFixed(2);
      const totalTime=convertTime(data['totalTime']);
      const totalFuel=totalDistance/100*$('#fuelConsumption').val();
      const totalTimeEstr=convertTime(convertTimeESTR(data['totalTime'])*3600);
      const startDate=$('#startDate').val();

      const footRow={
          'dateOfReturn': dateOfReturn(startDate, totalTimeEstr),
          'totalDistance': totalDistance,
          'totalTime': totalTime,
          'totalTimeEstr': totalTimeEstr,
          'fuelCost': totalFuel.toFixed(2)+' л. / '+(totalFuel*$('#fuelCost').val()).toFixed(2)+' EUR',
          'additCosts': totalDistance*$('#additCosts').val()
        };

         //request saveResultInXlsx
        self.props.onChange({type: 'ADD_BODY_ROW', bodyRows:rows});
        footRow.tollCost=(+data.totalCost).toFixed(2);
      
        if(!inputCheckFuel){
          footRow.checkFuel=false;
        }
        else{
          footRow.checkFuel=true;
        }
        self.props.onChange({type: 'ADD_FOOT_ROW', footRow:footRow});
        
        //paste rows in descrition table
        self.props.onChange({type:'SAVE_DESCRIPTION', description: data.description});

            // onChange for fuelConsumption
        $('.checkFuelConsumption').keyup(function(event){
          let index, valueFuel;
          const value=event.target.value;
          const cellsWithFuelCost = $('.result-table tbody .cell-fuelCost');
          const cellsWithDistance = $('.result-table tbody .cell-distance');
          let totalFuel=0;
                           
          document.querySelectorAll('.checkFuelConsumption').forEach((item, i)=>{
            if(event.target===item){
              index=i;
            }
            else{
              valueFuel=+cellsWithFuelCost[i].textContent.split('/')[0].replace(' л.', '')  
              totalFuel=totalFuel+valueFuel; 
            }
          });
    
          document.querySelectorAll('.error-checkFuel')[index].style.display='none';
          if(((event.keyCode>95&&event.keyCode<106)||(event.keyCode>47&&event.keyCode<58)||event.keyCode===8||event.key==='.')&&(+value<=50)){
            var fuel=(+cellsWithDistance[index].textContent)/100*(+value);
            totalFuel=totalFuel+fuel;
            cellsWithFuelCost[index].textContent=fuel.toFixed(2)+' л. / '+(fuel*$('#fuelCost').val()).toFixed(2)+ ' EUR';
            $('.result-table tfoot .cell-fuelCost').text('Расходы на топливо: ' + totalFuel.toFixed(2) + ' л. / ' + (totalFuel * $('#fuelCost').val()).toFixed(2) + ' EUR');
            return (event.key >= '0' && event.key <= '9') ||
            event.key === 'ArrowLeft' || event.key === 'ArrowRight' ||  event.key === 'Backspace';
           }
           else{
            document.querySelectorAll('.error-checkFuel')[index].style.display='block';
            $(this).val(value.replace(event.key, '')) 
           }
        });

        preloader('stop');
         // addListeners for button in the footer
   
         $('.routingDescriptionRow').click(function(e){
          const index=+e.target.attributes['data-index'].nodeValue;
         
          if( self.props.additMarker){
            self.props.additMarker.setMap(null);
          }
         
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(self.props.description[index].position.latitude, self.props.description[index].position.longitude),
            map: self.props.map,
            icon:'http://maps.google.com/mapfiles/kml/pal4/icon23.png'
          });
 
          self.props.onChange({type: 'SAVE_ADDIT_MARKER', marker:marker});
          self.props.map.setCenter(new google.maps.LatLng(self.props.description[index].position.latitude, self.props.description[index].position.longitude));
          self.props.map.setZoom(14);
          
         });
         if($('input[name="newTab"]').is(':checked')){
          let markers=[];
         
          self.props.markers.forEach(marker=>{
              markers.push(new google.maps.Marker({
                position: marker.getPosition(),
                title: marker.getTitle(),
                label: marker.getLabel(),
                draggable: false
              }));
          });
            self.props.onChange({type:'SAVE_ALL_MARKERS', newMarker: markers, index: self.props.tabs.length});
        
          $('.nav-tabs li:nth-of-type('+self.props.tabs.length+')').addClass('active'); 
          self.props.onChange({type: 'SAVE_TRACK', index: self.props.tabs.length, info:{ footRows:self.props.footRows, bodyRows: self.props.bodyRows, countriesDescription: self.props.countriesDescription, description: self.props.description, markers: markers, searchboxes: self.props.searchBoxes, cost: self.props.totalCost, minMax: self.props.minMaxCoords, mainPolyline: self.props.mainPolylines, allCoords: self.props.allCoords, freeTollRoads: self.props.freeTollRoads }});
       
         self.props.onChange({type:'SAVE_SETTINGS', index: self.props.tabs.length,  item:{
          car: $("input[name='truck']").is(':checked'),
          mode: mode,
          tollRoads: (stateTollRoads===true)?true:false,
          exception: exception,
          autoParams: truckParams,
          fuelConsumption: $('#fuelConsumption').val(),
          inputCheckFuel: inputCheckFuel,
          fuelCost: $('#fuelCost').val(),
          additCosts: $('#additCosts').val()
        }}); 
        }
       }
       else{
        //in case FALSE
        preloader('stop');
        self.props.resetModalWindow(dictionary[checkedMode]+' и оптимальный маршруты для '+dictionary[auto]+' не найдены. Не удалось построить маршрут по заданным параметрам, попробуйте изменить настройки с исключением платных дорог или параметры автомобиля.', 'Внимание', true);
        }
    });      
  }
}


function cleanMainTable(){
  const self=this;
  self.props.onChange({type: 'DELETE_FOOT_ROW'});
  self.props.onChange({type: 'DELETE_BODY_ROWS'});
  self.props.onChange({type: 'DELETE_COUNTRY_DESCRIPTION'});
  self.props.onChange({type: 'RESET_COST'});
}

function cleanDescriptionTable(){
  const self=this;
  self.props.onChange({
    type: 'DELETE_DESCRIPTION'
  });  
  $('.wrap-description').css('right', -500);
}

function drawMainRoute(self, coords, key, auto){
  let strokeColor;

  if(key==='coordTollRoad'){
    strokeColor='red';
  }
  else if(auto==='truck'){
    strokeColor='green';
  }
  else{
    strokeColor='blue';
  }

  coords.forEach(road=>{
    let polyline;
    let pathPoly=[];

      road.forEach(coord=>{     
          for(var a=0; a<coord.length; a=a+2){
            pathPoly.push(new google.maps.LatLng({lat: coord[a], lng: coord[a+1]}));
          }
      });

      polyline = new google.maps.Polyline({
          geodesic: true,
          strokeColor: strokeColor,
          strokeOpacity: 1.0,
          strokeWeight: 3,
          map: self.props.map
        });

        polyline.setPath(pathPoly);
        self.props.onChange({type: 'SAVE_MAIN_POLY', newPoly: polyline});
    });
}

function drawAdditRoutes(self){ 
 
  const allRoutes=self.props.allRoutes;
  for(let key in allRoutes){
      let coords=allRoutes[key]['info']['allCoords'];
      let polyline;
      let pathPoly=[];
            
        for(var a=0; a<coords.length; a=a+2){
          pathPoly.push(new google.maps.LatLng({lat: coords[a], lng: coords[a+1]}));
        }
    
          polyline = new google.maps.Polyline({
              geodesic: true,
              strokeColor: 'blue',
              strokeOpacity: 1,
              strokeWeight: 1.5,
              map: self.props.map
            });
    
          polyline.setPath(pathPoly);
          self.props.onChange({type: 'SAVE_ADDIT_POLY', newPoly: polyline});
      }
}

function drawAnimateCircle(coords, all){
  const self=(all)?all:this;
  const icon = {
    path: google.maps.SymbolPath.CIRCLE,
    scale: 5,
  };
  
  let mainPolyline = new google.maps.Polyline({
    geodesic: true,
    map: self.props.map,
    strokeWeight:0,
    icons: [
      {
        icon: icon,
        offset: '100%',
        map: self.props.map
      }
    ]
  });

  var path = [];
   for(var a=0; a<coords.length; a=a+2){
     path.push(new google.maps.LatLng({lat:coords[a], lng: coords[a+1]}));
   }
 
 mainPolyline.setPath(path);
 animateCircle(mainPolyline);
  self.props.onChange({type: 'ADD_POLYLINE', polyline: mainPolyline});
}

function preloader(type){
  if(type==='stop'){
      $('.preloader-overlay').remove();
  }
  else if(type==='start'){
      var preloader = $("<div id='jpreloader' class='preloader-overlay'><div class='loader' style='position:absolute;left:50%;top:50%;margin-left:-24px;margin-top:-24px;'><h3><strong>Построение маршрута...</strong></h3></div></div>");
      preloader.css({
          'background-color': '#4c4c4c',
          'width': '100%',
          'height': '100%',
          'left': '0',
          'top': '0',
          'opacity': '0.3',
          'z-index': '100',
          'position': 'absolute'
      });
      $('#preloader').append(preloader);
  }
}

function animateCircle(line) {
  var count = 0;
  window.setInterval(function () {
    count = (count + 1) % 200;

    var icons = line.get('icons');
    icons[0].offset = (count / 2) + '%';
    line.set('icons', icons);
  }, 80);
}

function addPoint() {
  this.props.onChangeCounterPoints();
  const newBox = <AddressSearchBox map = {
    this.props.map
    }
    onChangeCounterPoints={
      this.props.onChangeCounterPoints
    }
    key = {
      Math.random() * (9999 - 1111) + 1111
    }
    counterPoints={ this.props.counterPoints+1}
    valueSelect='mapSelect'
    indexSearchBox={this.props.counterPoints}
  > </AddressSearchBox>;
  this.props.onChange({
  type: 'ADD_SearchBox',
  newBox: newBox});
  this.props.onChange({
    type: 'ADD_POINT',
    newMarker: this.props.counterPoints + 1
  });
  this.props.onChange({
    type: 'SAVE_COORD',
    coord: ''
  });
}

function clean(){
  this.cleanMap();
  this.cleanMainTable();
  this.cleanDescriptionTable();
  this.deletePoints();
}

function gettingTruckParams(){
  let hazardousGoods='';
  const hazardousGood=$('input[name="shippedHazardousGoods"]:checked').val();
  const fullWeight=$('input[name="fullWeight"]').val();
  const height=$('input[name="height"]').val();
  const length=$('input[name="length"]').val();
  const width=$('input[name="width"]').val();
  const weightPerAxle=$('input[name="weightPerAxle"]').val();
  const numberAxles=$("input[name='numberOfAxles']:checked").val();
  const emissionType=$("input[name='emissionTypes']:checked").val();

  const truckParams={
    shippedHazardousGoods: (hazardousGood===1)?'explosive,gas,flammable,combustible':(hazardousGood===2)?'allHazardousGoods':'',
    width: (width!=='')?width+'m':width,
    height: (height!=='')?height+'m':height,
    length: (length!=='')?length+'m':length,
    weightPerAxle: (weightPerAxle!=='')?weightPerAxle+'t':weightPerAxle,
    limitedWeight: (fullWeight!=='')?fullWeight+'t':fullWeight,
    emissionType: emissionType,
    vehicleNumberAxles: numberAxles,
    hazardousGoods: hazardousGoods
  }

  return truckParams;
}

export {
  addPoint,             
  createRoute,
  cleanMainTable,
  cleanDescriptionTable,
  clean,
  drawAdditRoutes,
  drawAnimateCircle
};
