export default function searchBoxes(boxes = [], action) {
  if (action.type === 'ADD_SearchBox') {
    return [
      ...boxes,
      action.newBox
    ]
  } else if (action.type === 'SORT_SearchBox') {
    boxes=[]
    boxes = action.sortSearchBoxes;
    return [...boxes];
  } else if (action.type === 'DELETE_BOX') {
    boxes.splice(action.index, 1);

    return boxes;
  } else if (action.type === 'DELETE_SearchBox') {
    var newBoxes = [];
    boxes.forEach(item => {
      if (action.counter) {
        if (action.counter !== item.props.counterPoints) {
          newBoxes.push(item);
        }
      } else if (item !== action.search) {
        newBoxes.push(item);
      }
    })
   
    return boxes = newBoxes;
  } else if (action.type === 'DELETE_ALL_SearchBoxes') {
    return boxes = [];
  } else if (action.type === 'CHANGE_SearchBox') {

    return boxes.map((box, index) => {
      if (index === action.index) {
        

        return Object.assign({}, box, 
         action.newBox
        )
      }


      return box
    })
   
    
  }
  return boxes;
}