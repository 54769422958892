import React from 'react';
import {connect} from 'react-redux';
import ModalConfirmController from './WindowWithSettingsController';
import './WindowWithSettings.css';

const modalController=new ModalConfirmController();

class WindowSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state={
        open: this.props.openConfirm,
        showParams: true,
        showSettings: true,
        flagTruck: false  
    };
   
   this.closeModal=modalController.closeModal.bind(this);
   this.resetSettings=modalController.resetSettings.bind(this);
   this.saveSettings=modalController.saveSettings.bind(this);
   this.handleInputChanged=modalController.handleInputChanged.bind(this);
   this.showParamsTruck=modalController.showParamsTruck.bind(this);
   this.onChangeNumbersOfAxles=modalController.onChangeNumbersOfAxles.bind(this);
   this.onChangeEmission=modalController.onChangeEmission.bind(this);
   this.showAdditInfo=modalController.showAdditInfo.bind(this);
  }
 
  render() {
    return (
        <div className='window-with-params'>
            <div className='confirm-title'>
                <span className='left'>Параметры</span>
                <span className='rigth glyphicon glyphicon-remove-circle' onClick={this.closeModal}></span>
            </div>
        <div className="row" style={{'maxHeight':'330px', 'overflowY':'scroll','padding': '10px'}}>
            <div className='accordeon-body'>
                        <ul>  
                            <li>
                                <p><strong>Экологический стандарт: </strong><span className='info-emissionsType glyphicon glyphicon-question-sign' onClick={this.showAdditInfo}></span></p>
                                <p>
                                    <input defaultChecked={this.props.customProps.emissionType5} type="radio" id="emissionType1"
                                    name="emissionTypes" value="5"
                                    onChange={this.onChangeEmission} />
                                    <label htmlFor="emissionType1">Евро-5</label>

                                    <input defaultChecked={this.props.customProps.emissionType6} type="radio" id="emissionType2"
                                    name="emissionTypes" value="6"
                                    onChange={this.onChangeEmission} />
                                    <label htmlFor="emissionType2">Евро-6</label>
                                </p>
                            </li>    
                            <li>
                                <p><strong>Категории туннелей: </strong><span className='info-tunnelCategory glyphicon glyphicon-question-sign' onClick={this.showAdditInfo}></span></p>
                                <p>
                                    <input defaultChecked={this.props.customProps.tunnelCategory1} type="radio" id="tunnelCategory1"
                                    name="tunnelCategory" value="B" />
                                    <label htmlFor="tunnelCategory1">B</label>

                                    <input defaultChecked={this.props.customProps.tunnelCategory2} type="radio" id="tunnelCategory2"
                                    name="tunnelCategory" value="C"/>
                                    <label htmlFor="tunnelCategory2">C</label>

                                    <input defaultChecked={this.props.customProps.tunnelCategory3} type="radio" id="tunnelCategory3"
                                    name="tunnelCategory" value="D"/>
                                    <label htmlFor="tunnelCategory3">D</label>
                                    
                                    <input defaultChecked={this.props.customProps.tunnelCategory4} type="radio" id="tunnelCategory4"
                                    name="tunnelCategory" value="E"/>
                                    <label htmlFor="tunnelCategory4">E</label>
                                </p>
                            </li> 
                            <li>
                                <p><strong>Опасные грузы: </strong><span className='info-hazardousGoods glyphicon glyphicon-question-sign' onClick={this.showAdditInfo}></span></p>
                                <ul className='listHazardousGoods'>
                                    <li>
                                        <input defaultChecked={this.props.customProps.hazardousGood1} type="radio" id="hazardousGoods1"
                                        name="shippedHazardousGoods" value="1" />
                                        <label htmlFor="hazardousGoods1">Взрывоопасные вещества</label></li>

                                    <li>
                                        <input defaultChecked={this.props.customProps.hazardousGood2} type="radio" id="hazardousGoods2"
                                    name="shippedHazardousGoods" value="2" />
                                        <label htmlFor="hazardousGoods2">Любые опасные материалы</label>
                                    </li>

                                            <li>
                                                <input defaultChecked={this.props.customProps.hazardousGood3} type="radio" id="hazardousGoods3"
                                        name="shippedHazardousGoods" value="3" />
                                                <label htmlFor="hazardousGoods3">Нет</label>
                                            </li>
                                </ul> 
                            </li>  
                        </ul>
                        <p>
                            <span><strong>Параметры авто: </strong></span>
                            <span className='glyphicon glyphicon-chevron-up' onClick={this.showParamsTruck}></span>
                        </p>
                        <ul className='paramsOfTruck'>
                            <li> 
                                <p>
                                    <span>Вес, включая вес грузов и прицепов (т.): </span>
                                </p>
                                <p>
                                    <input 
                                        type='text' 
                                        name='fullWeight' 
                                        value={this.props.customProps.fullWeight} 
                                        onChange={this.handleInputChanged}/>
                                </p>
                                <p className='error error-fullWeight' >Полный вес грузового авто не должен превышать 1000 т.</p>
                            </li> 
                            <li> 
                                <p><span>Высота (м.): </span></p>
                                <p><input 
                                    type='text' 
                                    name='height' 
                                    value={this.props.customProps.height} 
                                    onChange={this.handleInputChanged}/></p>
                                <p className='error error-height' >Высота должна быть представлена целым числом и не должна быть более 50 м.</p>
                            </li>  
                            <li>
                                <p><span>Длина (м.): </span></p>
                                <p><input 
                                    type='text'  
                                    name='length' 
                                    value={this.props.customProps.length} 
                                    onChange={this.handleInputChanged}/></p>
                                <p className='error error-length' >Длина должна быть представлена целым числом и не должна быть более 300 м.</p>
                            </li>
                            <li>
                                <p><span>Ширина (м.): </span></p>
                                <p><input 
                                    type='text' 
                                    name='width' 
                                    value={this.props.customProps.width} 
                                    onChange={this.handleInputChanged}/></p>
                                <p className='error error-width'>Ширина должна быть представлена целым числом и не должна быть более 50 м.</p>
                            </li>
                            <li>
                                <p><span>Нагрузка на ось (т.): </span></p>
                                <p><input 
                                    name='weightPerAxle' 
                                    value={this.props.customProps.weightPerAxle} 
                                    onChange={this.handleInputChanged}/></p>
                                <p className='error error-weightPerAxle' >Вес должен быть представлен целым числом и авто не более 1000 т.</p>
                            </li>  
                            <li>
                                <p><span>Количество осей: </span></p>
                                <p>
                                    <input defaultChecked={this.props.customProps.numberOfAxles2} onChange={this.onChangeNumbersOfAxles} type="radio" id="axleChoice2"
                                    name="numberOfAxles" value="2" />
                                    <label htmlFor="axleChoice2">2 </label>

                                    <input defaultChecked={this.props.customProps.numberOfAxles3} onChange={this.onChangeNumbersOfAxles} type="radio" id="axleChoice3"
                                    name="numberOfAxles" value="3" />
                                    <label htmlFor="axleChoice3">3 </label>
                                
                                    <input defaultChecked={this.props.customProps.numberOfAxles4} onChange={this.onChangeNumbersOfAxles} type="radio" id="axleChoice4"
                                    name="numberOfAxles" value="4" />
                                    <label htmlFor="axleChoice4">4 </label>

                                    <input defaultChecked={this.props.customProps.numberOfAxles5} onChange={this.onChangeNumbersOfAxles} type="radio" id="axleChoice5"
                                    name="numberOfAxles" value="5" />
                                    <label htmlFor="axleChoice5">5 </label>
                                    
                                    <input defaultChecked={this.props.customProps.numberOfAxles6} onChange={this.onChangeNumbersOfAxles} type="radio" id="axleChoice6"
                                    name="numberOfAxles" value="6" />
                                    <label htmlFor="axleChoice6">6 </label>

                                    <input defaultChecked={this.props.customProps.numberOfAxles7} onChange={this.onChangeNumbersOfAxles} type="radio" id="axleChoice7"
                                    name="numberOfAxles" value="7" />
                                    <label htmlFor="axleChoice7">7 </label>
                                </p> 
                            </li>      
                        </ul>
            </div>
     
       </div>
       <div style={{'padding':'10px', 'borderTop': '1.6px dotted rgb(0, 125, 125)', 'textAlign':'right'}}>
            <button  
                style={{'marginBottom': '10px'}}
                title="Сбросить к настройкам по умолчанию" 
                className='btn'
                onClick={this.resetSettings}>
                    <span>Сбросить к настройкам по умолчанию</span>
            </button> 
        <div>
            <button  
                    title="Закрыть окно с параметрами" 
                    className='btn'
                    onClick={this.closeModal}>
                        <span>Отмена </span>
                    
                </button>  
                <button  
                    title="Применить пользовательские настройки. Они будут также сохранены в произвольных свойствах пользователя." 
                    className='btn'
                    style={{'minWidth':'55px', 'color':'white', 'backgroundColor':'rgba(0,125,125,.8)','marginLeft': '10px'}}
                    onClick={this.saveSettings}>
                        <span>Ок </span>
                    
            </button> 
        </div>
            
        </div>
      </div>
    );
  }
}

export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point)
    }
  })
)(WindowSettings);           

