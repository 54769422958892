export default function routes(routes = [], action) {
  if (action.type === 'SAVE_ROUTES') {
    return routes=action.routes;
  }  else if (action.type === 'DELETE_ROUTE') {
    let newRoute = [];
    routes.forEach(item => {
      if (item !== action.search) {
        newRoute.push(item);
      }
    })
    return routes = newRoute;
  }
  return routes;
}