
const google=window.google;
class WialonRoutesController{
    
  saveRoute() {
        if (this.props.coordinates.length > 1) {
          this.setState({
            openConfirm: !this.state.openConfirm
          });
        } else {
          this.props.resetModalWindow('Для сохранения маршрута необходимо задать как минимум две контрольные точки', 'Ошибка');
        }
  }

changeSelectOption(value) {
  this.cleanMap();
  const routes = this.props.routes;
  this.deletePoints();
  routes.forEach(item => {
    if (item.value === value) {
      item.points.forEach((marker, index) => {
        let y, x, latlng;
        if(marker.gz){
            y=marker.gz.b.cen_y;
            x=marker.gz.b.cen_x;
        }
        else{
            y=marker.y;
            x=marker.x;
        }
        latlng=new google.maps.LatLng(y, x);
        this.addPoint(y, x, latlng, marker.n); 
      });
    }
  });
}

  saveRoutingName(name) {
      const self = this;
      const wialon=window.wialon;
      const sess = wialon.core.Session.getInstance();
      const user = sess.getCurrUser();
      let pointsRoute = [];

      this.props.markers.forEach((marker, index) => {
        pointsRoute[index] = {
          f: 1,
          n: marker.title,
          r: 500,
          x: marker.getPosition().lng(),
          y: marker.getPosition().lat()
        }
      });

      sess.createRoute(user, name, 1, function (code, route) {
        if (!code) {
          route.updateCheckPoints(pointsRoute, function (callback) {
            if (callback === 0) {
              self.props.resetModalWindow('Маршрут ' + name + ' сохранен в Вашей учетной записи в Wialon. Для отображения в общем списке маршрутов необходимо перезагрузить страницу.', 'Внимание');
            } else {
              self.props.resetModalWindow('Не удалось сохранить маршрут в системе Wialon. Повторите попытку.', 'Ошибка');
            }
          });
        } else {
          self.props.resetModalWindow('Не удалось сохранить маршрут в системе Wialon. Воможно, у пользователя недостаточно прав для сохранения маршрута. Обратитесь, пожалуйста, в техподдержку для консультации', 'Ошибка');
        }
      });    
    }

  openConfirm(){
      this.setState({
        confirmDeleteRoute: !this.state.confirmDeleteRoute
      });
  }

  openConfirmDeleteRoute(){
      this.setState({
        confirmDeleteRoute: true
      });
    }

  changeStatusWindow() {
      this.setState({
        openConfirm: !this.state.openConfirm
      });
  }

  deleteRoute() {
      const $=window.$;
      const wialon=window.wialon;
      const self=this;
      const routes=this.props.routes;
      const sess = wialon.core.Session.getInstance();
      routes.forEach((route, index)=>{
        if($('.select-route').val()===route.value){
          sess.deleteItem(route.full, function(code){
            if(!code){
              routes.splice(index, 1);
              self.props.onChange({type: 'SAVE_ROUTES', routes: routes});
              self.deletePoints();
              self.props.resetModalWindow('Маршрут '+route.name+' удален из Wialon. Для отображения актуального списка необходимо обновить страницу.', 'Внимание');
            }
            else{
              self.props.resetModalWindow('Маршрут '+route.value+' не удалось удалить из системы Wialon. Повторите попытку.', 'Ошибка');
            }
          });
        }
    }); 
  }

}
export default WialonRoutesController;