import React, {Component} from 'react';
import {connect} from 'react-redux';
import DescriptionTableController from './DescriptionTableController';
import './DescriptionTable.css';

const table=new DescriptionTableController();

class DescriptionTable extends Component{
    constructor(props){
        super(props);
        this.state={
            visibleDescription: false
        }
        this.closeTable=table.closeDescriptionTable.bind(this);
        this.saveTable=table.saveDescriptionTable.bind(this);
    }

    componentDidMount(){
        const self=this;
        document.body.addEventListener('keydown', function(e) {
           if(e.keyCode===27){
                self.closeTable();
            };
        });
    }

    render(){
       const tableRow = this.props.description.map((item, index) => {
            let span = document.createElement('span'); //Костыль!
            span.innerHTML = item.description;

            if(item['flagsTollRoad']){ 
                return (
                    <tr key={index} title='Платная дорога' style={{background: "lightgray", color: "red", fontWeight: "bold", fontSize: "13.8px"}}>
                        <td className="routingDescriptionRow" data-index={index}>
                            <span className="glyphicon glyphicon-ok" /> {span.textContent}
                        </td>
                    </tr>
                );
            }
            else{
                return (
                    <tr key={index}>
                        <td className="routingDescriptionRow" data-index={index}>
                            <span className="glyphicon glyphicon-ok" /> {span.textContent}
                        </td>
                    </tr>
                );
            }  
        });
        
        return(
            <div className='wrap-description' >
                <div className='row settingsDescription'>
                    <div className='col-xs-6'>
                        <span 
                            className='btn glyphicon glyphicon-remove' 
                            title='Скрыть описание можно также нажатием клавиши Esc' 
                            onClick={this.closeTable}>
                        </span>
                    </div>
                    <div className='col-xs-6'>
                        <span 
                            className='btn glyphicon glyphicon-download-alt right' 
                            title='Загрузить описание маршрута в формате xlsx' 
                            onClick={this.saveTable}>
                        </span>
                    </div>
                </div>
                <table className='routingDescription'>
                    <tbody className='body-routingDescription'>
                        {tableRow}
                    </tbody>
                </table>
            </div>
        );
    }
}
export default connect(
    state => state,
    dispatch => ({
      onChange: (point) => {
      dispatch(point)
    }
  })
)(DescriptionTable);

 