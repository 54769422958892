const $=window.$;

class MainTableController{

    showDescription(){
        const valueDisplay= ($('.result-table').css('display')==='table')?'none':'table';
        $('.result-table').css('display', valueDisplay);
    }

}

export default MainTableController;