const $=window.$;

class TruckSettingsController{

    showSettings() {
        $('.window-with-params').css('display', 'block');
        $('.main-wrap').css('opacity', 0.45).css('pointer-events', 'none');
    }

    changeTypeAuto(event) {
        const checked=event.target.checked;     
        let display;
        this.setState({
          flagTruck: checked
        });

        switch (checked) {
            case true:
                display = 'block';
                break;
            case false:
                display = 'none';
                break;
            default:
                break;
        }

        $('.truck-settings').css('display', display);
        if(!checked){
            $('.error-input').css('display', 'none');
            $('.wrap-searchInput').css('color', 'black')
            $(".wrapper-settings .list-mode").append("<li title='Тип основан на расстоянии'><input type='radio' name='mode' value='shortest' /><span>  Короткий</span></li>");
            $('.accordeon-body').css('display', display);
            this.setState({
                showSettings: checked
            });
        }
        else{
            this.props.errors.forEach(element => {
                $($('.error-input')[element]).css('display', 'block');
                $($('.wrap-searchInput')[element]).css('color', 'darkBlue');
            });
            $('.wrapper-settings .list-mode li').remove(':last-child');
        }
    }
}

export default TruckSettingsController;