const axios = require('axios');
const wialon = window.wialon;
const $ = window.$;

class DescriptionTableController {

    closeDescriptionTable() {
        const self = this;
        $('.wrap-description').css('right', -500);
        $('.show-description').attr('checked', false);
        const additMarker=self.props.additMarker;
        self.props.map.setZoom(4);
        if( additMarker){
            additMarker.setMap(null);
        }
    }

    saveDescriptionTable() {
        const self = this;
        const sess = wialon.core.Session.getInstance();
        const user = sess.getCurrUser().getName();
        axios.post('/saveDescriptionRoute', {
                description: this.props.description,
                userName: user
            })
            .then(function (response) {
                if (!response) {
                    self.props.resetModalWindow('Файл не может быть сохранен', 'Внимание');
                } else {
                    const link = document.createElement('a');
                    link.setAttribute('href', '/store/descriptionRoute-' + user + '.xlsx');
                    link.setAttribute('download', 'descriptionRoute-' + user + '.xlsx');
                    window.onload = link.click();
                }
            });
    }
}

export default DescriptionTableController;