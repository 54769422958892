export default function allMarkers(additMarkers={}, action) {
    if(action.type==='SAVE_ALL_MARKERS'){
      additMarkers[action.index]=action.newMarker;
      return additMarkers;
    }
    else if(action.type==='DELETE_ADDIT_MARKER'){
      for(let i in additMarkers){
        if(+i===action.index){
          delete additMarkers[i];
        }
        else if(+i>action.index){
          additMarkers[+i-1]=additMarkers[i];
          delete additMarkers[i];
        }
      }
      return additMarkers;
    }
    else if(action.type==='DELETE_ALL_MARKERS'){
      if(action.activeTrack){
        additMarkers={};
        additMarkers['1']=action.markers;
        return additMarkers;
      }
      else{
        return additMarkers={};
      }
    }
    return additMarkers;
  }