const $ = window.$;

class DateTimePickerController {

    onFocusDatePicker(event) {
        const name=event.target.name;
        $.datetimepicker.setLocale('ru');
        $('#'+name).datetimepicker({
            lang: 'ru',
            minDate: 0, 
            minTime: 0,
            format: 'd-m-Y H:i',
            step: 10
        });
    }
 
    onChange(event) {
        const monthReg = '(0[1-9]|1[0-2])'; // a number between 00 and 12
        const dayReg = '(0[1-9]|1[0-9]|2[0-9]|3[0-1])'; // a number between 00 and 31
        const hourReg = '([0-1][0-9]|2[0-3])'; // a number between 00 and 24
        const minReg = '([0-5][0-9])'; // a number between 00 and 59
        const value = event.target.value;
        const id=event.target.id;     
        let y;
            switch (value) {
                case 'startDate':
                    y = 9;
                    break;
                default:
                    y = '[0-9]';
                    break;
            }
    
        const yearReg = '(201' + y + '|20[2-9][0-9])'; 
        const reg = new RegExp('^' + dayReg + '-' + monthReg + '-' + yearReg + ' ' + hourReg + ':' + minReg + '$', 'g');
            if (value.match(reg)) {
                $('.error-' + id).css('display', 'none');
            } else {
                $('#' + id).attr('disabled', false);
                $('.error-' + id).css('display', 'block');
            }
        }
}

export default DateTimePickerController;