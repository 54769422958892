import React from 'react';
import axios from 'axios';
import AddressSearchBox from '../Search/SearchBox';

const google = window.google;
const $ = window.$;
const wialon=window.wialon;

function handleMapClick(event) {
    const self=this;
    const sess = wialon.core.Session.getInstance();
    let lat, lng, latlng;
    self.cleanMap();
    
    if(event.target){     
        if($('.accordeon-wrap-bothCoord').css('display')==='none'){
            lat=+$('.point-lat').val();
            lng=+$('.point-lng').val();
        }
        else{
            lat=+$('.input-latLng').val().split(',')[0];
            lng=+$('.input-latLng').val().split(',')[1];
        }
        latlng=new google.maps.LatLng(lat, lng);   
    }
    else{
        lat=event.latLng.lat();
        lng=event.latLng.lng();
        latlng=event.latLng;
    }
    axios.post('/geocoding', { 
        lat: lat,
        lng: lng,
        id:sess.getCurrUser().getId()
    })
    .then(function (response) {
        if (response.data.label) {
            self.addPoint(lat, lng, latlng, response.data.label); 
        } 
        else {
            self.props.resetModalWindow('Невозможно добавить маркер в этой точке.', 'Ошибка', true);
        }
    });     
}
/*

function clickMarker(event) {
    this.cleanMap();
    const self=this;
    let markers = self.props.markers;
    let searchBoxes = self.props.searchBoxes;
    let coords=self.props.coordinates;

    markers.forEach((item, index) => {
        if (typeof(item)!=='number'&&item.getPosition().lat() === event.latLng.lat() && item.getPosition().lng() === event.latLng.lng()) {
            item.setMap(null);
            searchBoxes.splice(index, 1);
            this.props.onChange({
                type: 'SORT_SearchBox',
                sortSearchBoxes: searchBoxes
            });
            markers.splice(index, 1);
            this.props.onChange({
                type: 'DELETE_POINT',
                markers: markers
            });    
            coords.splice(index, 1);
            this.props.onChange({
                type: 'DELETE_COORD',
                coords: coords
            });  
        }
    });
    const pointsLabel=$('.point-label');
    markers.forEach((item, index)=>{
        if(typeof(item)!=='number'){
            item.setLabel(index+1+'');
        }
        $(pointsLabel[index]).text(index+1);
    });

    this.drawLine();
    this.props.onChangeCounterPoints(markers.length);
}  
*/
function dragMarker(event) {
    this.cleanMap();
    const self = this;
    let markers=self.props.markers;
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    let index;
    
    markers.forEach((item, i) => {
        if (typeof (item) !== 'number' && item.getPosition().lat() === lat && item.getPosition().lng() === lng) {
            index=i;
        }
    });
    
    wialon.util.Gis.getLocations([{lon:lng, lat:lat}], function(code, address){
        self.updateMarker(index, lng, lat, address[0]);
        self.updateSearchBox(index, address[0], null, 'mapSelect');
        self.cleanMap();
        self.props.onChange({type:'UPDATE_COORD', index: index, coord: new google.maps.LatLng({lng:lng, lat:lat})});
        self.props.onChange({type:'UPDATE_ERRORS', index: index});
        self.drawLine();
    });
}


function updateMarker(index, lng, lat, address){ 
    const self=this;
    let markers=self.props.markers;
    let updateMarker;

    if(typeof(markers[index])!=='number'){
        markers[index].setMap(null);
    }
    updateMarker = new google.maps.Marker({
        position: {
            lng: lng,
            lat: lat
        },
        map: self.props.map,
        label:index+1 + '',
        draggable: true,
        clickable: true,
        title: address
        });
    
    //updateMarker.addListener('click', clickMarker.bind(self));
    updateMarker.addListener('dragend', dragMarker.bind(self));
        
    markers.splice(index, 1, updateMarker);
    self.props.onChange({
        type:'SORT_POINT',
        sortPoints: markers
    });     
  }

function updateSearchBox(indexBox, address, indexSelectedOption, valueSelect, indexSelectedPostalCode){
    const self=this;
    let  searchBoxes=self.props.searchBoxes;
    const newBox= <AddressSearchBox map = {
        self.props.map
        }
        indexSearchBox= {indexBox}
        key = {
            Math.random() * (9999 - 1111) + 1111
        }
        selectSearch={
            true
        }
        indexSelectedPostalCode={indexSelectedPostalCode}
        valueSelect={valueSelect}
        counterPoints = {
            indexBox+1
        }
        onChangeCounterPoints = {
            self.props.onChangeCounterPoints
        }
        indexSelectedOption={indexSelectedOption}
        value = {
            address
        }> </AddressSearchBox>;

    searchBoxes.splice(indexBox, 1, newBox);
    self.props.onChange({
        type: 'SORT_SearchBox',
        sortSearchBoxes: searchBoxes
    });   
}

function cleanMap(){
    const self=this;
    const allPolyline=self.props.mainPolylines;
    const checkedPolyline=self.props.checkedPolyline;
    const additPolylines=self.props.additPolylines;
    const polyline=self.props.polyline;
    const additMarker= self.props.additMarker;
    const line = self.props.line;
    const nearestZones=self.props.nearestZones;
    const markerCluster=self.props.markerClusterrer;

    if(nearestZones.length>0){
        $('.wrap-fuelStations').css('display', 'none');
        markerCluster.clearMarkers();
        nearestZones.forEach(marker=>{
        marker.setMap(null);
        });
    }
    line.setPath([]);
    additPolylines.forEach(poly=>{
        poly.setPath([]);
    });
    checkedPolyline.forEach(poly=>{
        poly.setPath([]);
    });

    if(polyline){
        polyline.setPath([]);
        allPolyline.forEach(item=>{
            item.setPath([]);
        });

        if( additMarker){
            additMarker.setMap(null);
        }
        self.props.onChange({type:'DELETE_COORDS_OF_TRACK'})
        self.props.onChange({type: 'DELETE_POLYLINE'});
        self.props.onChange({type: 'DELETE_MAIN_POLYLINES'});
        self.props.onChange({type: 'DELETE_CHECKED_POLYLINE'});
        self.props.onChange({type: 'DELETE_ADDIT_POLYLINES'});
    }
}

function deleteMarker(index){ 
    const self=this;
    let markers=self.props.markers;
    let coordinates=self.props.coordinates;
    if (typeof (markers[index]) !== 'number') {
        markers[index].setMap(null);
    }
    
    markers.splice(index, 1, index);
    self.props.onChange({
      type: 'SORT_POINTS',
      sortPoint: markers
    });
    coordinates.splice(index, 1, '');
    self.props.onChange({
        type: 'SORT_COORD', 
        sortCoord: coordinates
    });
  }

function addPoint(lat, lng, latlng, label){
    const self=this;
    self.props.onChangeCounterPoints();
    self.props.onChange({
        type: 'SAVE_COORD',
        coord: latlng
    });
    const marker = new google.maps.Marker({
        position: {
            lng: lng,
            lat: lat
        },
        map: self.props.map,
        label: self.props.counterPoints+ '', 
        draggable: true,
        clickable: true,
        title: label
    });
    
    //marker.addListener('click', self.clickMarker);
    marker.addListener('dragend', self.dragMarker);
     
    self.props.onChange({
        type: 'ADD_POINT',
        newMarker: marker
    });

    self.props.onChange({
        type: 'ADD_SearchBox',
        index: self.props.counterPoints,
        newBox: <AddressSearchBox map = {
            self.props.map
        }
        indexSearchBox= {self.props.counterPoints-1}
        key = {
            Math.random() * (9999 - 1111) + 1111
        }
        selectSearch={
            true
        }
        valueSelect='mapSelect'
        counterPoints = {
            self.props.counterPoints
        }
        onChangeCounterPoints = {
            self.props.onChangeCounterPoints
        }
        value = {
             label
        }> </AddressSearchBox>
    });
    
    self.drawLine();
}           

function deletePoints() {
    const self = this;
    const markers = self.props.markers;
    const additMarkers=self.props.allMarkers;
    const line = self.props.line;

    self.props.onChange({type: 'DELETE_ERRORS'});
    line.setPath([]);
    markers.forEach(marker => {
      if (typeof (marker) !== 'number') {
        marker.setMap(null);
      }
    });
    for(let key in additMarkers){
        additMarkers[key].forEach(item=>{
            item.setMap(null);
        }); 
    }

    self.props.onChange({type: 'DELETE_COORDS'});
    self.props.onChange({
      type: 'DELETE_ALL_COORDS'
    });
    self.props.onChange({
      type: 'DELETE_ALL_POINTS'
    });
    self.props.onChange({
      type: 'DELETE_ALL_SearchBoxes'
    })
    self.props.onChangeCounterPoints(0);
    self.props.onChange({
      type: 'DELETE_DESCRIPTION'
    });

    $('.wrap-description').hide(250, function () {
        $('.wrap-description').css('right', -500);
    });
    $('.wrap-result-table').hide(250, function () {
        $('.wrap-result-table').css('maxHeight', 0);
        $('.nav-tabs').css('display', 'none');
        $('.wrap-btn-save').css('display', 'none');
    });
    $('#wrapper-map').css('height', '100%');
}

function drawLine(){
    const line = this.props.line;
    let coords=this.props.coordinates;
    let path=[];
    coords.forEach(item => {
        if(item){
            path.push(item);
        }
    });
    line.setPath(path);
}
                
export {
    dragMarker,
    handleMapClick,
   // clickMarker,            
    cleanMap,
    addPoint,
    updateMarker,
    updateSearchBox,
    drawLine,
    deleteMarker,
    deletePoints
};
