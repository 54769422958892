import axios from 'axios';
const wialon = window.wialon;
const sess = wialon.core.Session.getInstance();

class WialonController {

  initWialonSession(param){
    const self = this;
    sess.initSession("https://hst-api.wialon.com");
    
    if (param.token) {
      sess.loginToken(param.token, "", function(code) {callbackInit(code); })
    } 
    else if (param.sid) {
      sess.duplicate(param.sid, "", true, function(code) {callbackInit(code); })
    }
      function  callbackInit(code){
          if (code) {
            self.setState({
              authorized: false
            });
          } else {
            const user = sess.getCurrUser().getName();
            self.setState({
              authorized: true,
              userName: user
            });

          self.loadZones();
          self.loadRoutes();
          self.loadObjects();
          self.loadCustomProperties();
      }
    }
  }

  loadingPostalCodes() {
    const self = this;
    axios.post('/getPostalCodesAndPogranBorders')
      .then(function (response) {
        self.props.onChange({
          type: 'SAVE_CODES',
          codes: response.data.post_code
        });
        self.props.onChange({
          type: 'SAVE_BORDERS',
          borders: response.data.pogran_perehod
        });
      });
  }

  loadCustomProperties(){
    const sess = wialon.core.Session.getInstance();
    const user = sess.getCurrUser();
    const customProps=user.getCustomProps();
    if(customProps['Параметры в Километражнике']){
      this.props.onChange({
        type: 'SAVE_CUSTOM_PROPS',
        props: JSON.parse(customProps['Параметры в Километражнике'])
      });
    }
    else{
      const newCustomProps={
          emissionType5: false, 
          emissionType6: true,
          hazardousGood1: false,
          hazardousGood2: false,
          hazardousGood3: true,
          tunnelCategory1: false,
          tunnelCategory2: false,
          tunnelCategory3: false,
          tunnelCategory4: false,
          numberOfAxles2: false,
          numberOfAxles3: false,
          numberOfAxles4: false,
          numberOfAxles5: true,
          numberOfAxles6: false,
          numberOfAxles7: false,
          fullWeight: "40",
          weightPerAxle: "10",
          height: "4",
          length: "16.5",
          width: "2.55"
      };
      this.props.onChange({
        type: 'SAVE_CUSTOM_PROPS',
        props: newCustomProps
      });
      user.updateCustomProperty('Параметры в Километражнике', JSON.stringify(newCustomProps));
    }
  }

  loadZones() {
    const self = this;
    const flags = wialon.item.Item.dataFlag.base | wialon.item.Resource.dataFlag.zones | wialon.item.Resource.dataFlag.zoneGroups;

    sess.loadLibrary("resourceZones");
    sess.loadLibrary('resourceZoneGroups');
    sess.updateDataFlags([{
        type: "type",
        data: "avl_resource",
        flags: flags,
        mode: 0
      }],
        function (code) {
            if (code) {
              console.log(wialon.core.Errors.getErrorText(code));
            }
            const res = sess.getItems("avl_resource");
            if (!res || !res.length) {
              return;
            } else {
                let allGeofences = [];
                let additZones = [];
              //  let groupId = [];

                res.forEach(item => {
                  const zones = item.getZones();
                  const zonesGroups = item.getZonesGroups();
                  for (let group in zonesGroups) {
                      const zonesId = zonesGroups[group]['zns'];
                      if (zonesGroups[group]['n'].indexOf('!Километражник') !== -1) {
                          zonesId.forEach(id => {
                              for (let zone in zones) {
                                  if (zones[zone]['id'] === id) {
                                    additZones.push(zones[zone]);
                                  }
                              }
                          });
                      } 
                   //groupId = zonesId;    
                  }
                  /*
                  additZones.map(function (item) {
                      for (let zone in zones) {
                          if (zones[zone].n === item.n && groupId.indexOf(item.id) === -1) {
                            console.log(zones);
                            
                              delete zones[zone];
                          }
                      }
                      return item;
                  });
                  */
                  for (let key in zones) {
                      allGeofences.push({
                          name: zones[key].n,
                          lng: zones[key].b.cen_x,
                          lat: zones[key].b.cen_y
                      });
                  }
              });
              //console.log(additZones.length, allGeofences.length);
              self.props.onChange({
                type: 'SAVE_ZONES',
                zones: allGeofences
              });
              
              self.props.onChange({
                type: 'SAVE_ADDIT_GEOFENCES',
                geofences: additZones
              });
            }
          });
  }

  loadRoutes() {
    const self = this;
    const forceRefresh = 1;
    const dataFlags = 513;
    const indexFrom = 0;
    const indexTo = 0;
    const searchSpec = {
      itemsType: 'avl_route',
      propName: 'sys_name',
      propValueMask: '*',
      sortType: 'sys_name',
      propType: ''
    }
    
    sess.searchItems(searchSpec, forceRefresh, dataFlags, indexFrom, indexTo, function (callback, data) {
        const routes = data.items;
        let newRoute = [];
        routes.forEach((item, i) => {
            newRoute[i] = {};
            newRoute[i].value = 'route' + i;
            newRoute[i].id = item.getId();
            newRoute[i].name = item.getName();
            newRoute[i].points = item.getCheckPoints();
            newRoute[i].full = item
        });

        self.props.onChange({
          type: 'SAVE_ROUTES',
          routes: newRoute
        });
    });
  }

  loadObjects() {
    const self = this;
    const flags = wialon.item.Item.dataFlag.base | wialon.item.Unit.dataFlag.lastMessage;
    sess.loadLibrary("itemIcon");
    sess.updateDataFlags(
      [{
        type: "type",
        data: "avl_unit",
        flags: flags,
        mode: 0
      }],
      function (code) {
        if (code) {
          console.log(wialon.core.Errors.getErrorText(code));
          return;
        }

        const units = sess.getItems("avl_unit");
        let objects = [];
        units.forEach(unit => {
          if (unit.getPosition()) {
            objects.push({
              name: unit.getName(),
              id: unit.getId()
            });
          }
        });

        self.props.onChange({
          type: 'SAVE_CARS',
          cars: objects
        });
    });
  }
}

export default WialonController;