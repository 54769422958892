import React, {
    Component, Fragment
  } from 'react';
import './MainTable.css';
import {connect} from 'react-redux';
import MainTableController from './MainTableController';
const MainTableCtrl=new MainTableController();

class ResultTable extends Component {      
    constructor(props){
        super(props);
        this.showDescription=MainTableCtrl.showDescription.bind(this);
    }

    render() {
        const headRows=this.props.footRows.map((element, i)=>{
            if(!element.checkFuel){    
                    return (
                        <tr key={i}>
                            <th></th>
                            <th>Пункт отправления</th>
                            <th>Пункт прибытия</th>
                            <th>Расстояние, км</th>
                            <th>Время в пути</th>
                            <th>Время в пути по ЕСТР</th>
                            <th>Топливные расходы</th>
                            <th>Дополнительные расходы, EUR</th>
                        </tr>
                    );
                }
                else{
                    return (
                        <tr key={i}>
                            <th></th>
                            <th>Пункт отправления</th>
                            <th>Пункт прибытия</th>
                            <th>Расстояние, км</th>
                            <th>Время в пути</th>
                            <th>Время в пути по ЕСТР</th>
                            <th>Топливные расходы</th>
                            <th>Расход топлива, л/100км</th>
                            <th>Дополнительные расходы, EUR</th>
                        </tr>
                    );
                } 
            });

            const bodyRows= this.props.bodyRows.map((element, i) => {  
                if(element.checkFuelValue){
                return (
                    <tr key={i}>
                        <td>{i+1}</td>
                        <td>{element.point0}</td>
                        <td>{element.point1}</td>
                        <td className='cell-distance'>{element.distance}</td>
                        <td>{element.time}</td>
                        <td>{element.timeEstr}</td>
                        <td className='cell-fuelCost'>{element.fuelCost}</td>
                        <td>
                            <input type='text' className='checkFuelConsumption'/>
                            <p className='error error-checkFuel'>* Расход не должен быть более 50л/100км.</p>
                        </td>
                        <td className='cell-additCosts'>{element.additCosts}</td>
                    </tr>
                );
                }
                else{
                return (
                    <tr key={i}>
                        <td>{i+1}</td>
                        <td>{element.point0}</td>
                        <td>{element.point1}</td>
                        <td className='cell-distance'>{element.distance}</td>
                        <td>{element.time}</td>
                        <td>{element.timeEstr}</td>
                        <td className='cell-fuelCost'>{element.fuelCost}</td>
                        <td className='cell-additCosts'>{element.additCosts}</td>
                    </tr>
                );
                }
        });

        const footRows=this.props.footRows.map((element, i)=>{
            if(!element.checkFuel){    
                return (
                    <tr key={i}>
                        <th></th>
                        <th></th>
                        <th>{'Время прибытия: '+element.dateOfReturn}</th>
                        <th className='cell-distance'>{'Общее расстояние: '+ element.totalDistance + ' км'}</th>
                        <th>{'Общее время: ' + element.totalTime}</th>
                        <th>{'Общее время по ЕСТР: ' + element.totalTimeEstr}</th>
                        <th className='cell-fuelCost'>{'Расходы на топливо: '+element.fuelCost}</th>
                        <th className='cell-additCosts'>{'Доп. расходы, EUR: '+element.additCosts}</th>
                    </tr>
                );
            }      
            else{
                return (
                    <tr key={i}>
                        <th></th>
                        <th></th>
                        <th>{'Время прибытия: '+element.dateOfReturn}</th>
                        <th className='cell-distance'>{'Общее расстояние: '+ element.totalDistance + ' км'}</th>
                        <th>{'Общее время: ' + element.totalTime}</th>
                        <th>{'Общее время по ЕСТР: ' + element.totalTimeEstr}</th>
                        <th className='cell-fuelCost'>{'Расходы на топливо: '+element.fuelCost}</th>
                        <th></th>
                        <th className='cell-additCosts'>{'Доп. расходы, EUR: '+element.additCosts}</th>
                    </tr>
                );
            }            
        });

        return (
            <Fragment> 
                    <div onClick={this.showDescription}>
                        <span>Подробная информация о маршруте </span>
                        <span className='right glyphicon glyphicon-chevron-down'></span>
                    </div>
                    
                    <table className='result-table'>
                        <thead>
                            {headRows}
                        </thead>
                        <tbody>
                            {bodyRows}
                        </tbody>
                        <tfoot>
                            {footRows}
                        </tfoot>
                    </table>
            </Fragment>
        );
    }
}
  
export default connect(
    state => state,
    dispatch => ({
        onChange: (point) => {
        dispatch(point)
        }
    })
)(ResultTable);
  