import axios from 'axios';

const $ = window.$;
const wialon = window.wialon;
const google = window.google;
const latlng2distance=window.latlng2distance;
const MarkerClusterer = window.MarkerClusterer;

class FuelStationsController {

    hideFuelStations() {
        const nearestZones = this.props.nearestZones;
        const markerCluster = this.props.markerClusterrer
        if (nearestZones.length > 0) {
            this.props.onChange({
                type: 'DELETE_NEAREST_COORDS'
            });
            $('.wrap-fuelStations').css('display', 'none');
            markerCluster.clearMarkers();
            nearestZones.forEach(marker => {
                marker.setMap(null);
            });
        }
    }

    loadPointsInfo() {
        const self = this;
        const user = wialon.core.Session.getInstance().getCurrUser().getName();
        axios.post('/loadXLSXFile', {
            zones: self.props.nearestCoords,
            userName: user
            })
            .then(function (response) {
                if (!response.data) {
                    self.props.resetModalWindow('Файл не может быть сохранен', 'Внимание');
                } else {
                    const link = document.createElement('a');
                    link.setAttribute('href', '/store/tableWithPoints-' + user + '.xlsx');
                    link.setAttribute('download', 'tableWithPoints-' + user + '.xlsx');
                    window.onload = link.click();
                }
            });
    }

    showFuelStations() {
        const hiddenPolyline = this.props.polyline;
       // const coords = this.props.minMaxCoords;
        const nearestMarkers = this.props.nearestZones;
        const markerClusters = this.props.markerClusterrer;
        const allCoords=this.props.allCoords;
        if (nearestMarkers.length !== 0) {
            markerClusters.clearMarkers();
            nearestMarkers.forEach(marker => {
                marker.setMap(null);
            });
        }

        if (hiddenPolyline && hiddenPolyline.getPath().length !== 0) {
            let nearestZones = [];
            let nearestCoords = [];
            let result=[];
            let distance;
            const points=this.props.additGeofences;
            for(let i=0; i<allCoords.length-1; i++){
                for(let j=0; j<points.length; j++){  
                    distance=latlng2distance(allCoords[i], allCoords[i+1], points[j]['b']['cen_y'], points[j]['b']['cen_x']); 
                    if(distance<2){   
                        const marker = new google.maps.Marker({
                            position: new google.maps.LatLng({
                                lat: points[j]['b']['cen_y'],
                                lng: points[j]['b']['cen_x']
                            }),
                            title: points[j]['n'],
                            icon: '/images/fuel_station.png',
                            scaledSize: 0.1
                        });
        
                        if(result.indexOf(points[j]['b']['cen_y']+','+points[j]['b']['cen_x'])===-1){
                            result.push(points[j]['b']['cen_y']+','+points[j]['b']['cen_x']);
                            nearestZones.push(marker);
                            nearestCoords.push({
                                lat: points[j]['b']['cen_y'],
                                lng: points[j]['b']['cen_x'],
                                title: points[j]['n']
                            });
                        }    
                    }
                }
            } 
            
            /*
            additGeofences.forEach(item => {
                if (item.b.cen_y >= coords.minLat && item.b.cen_y <= coords.maxLat && item.b.cen_x >= coords.minLng && item.b.cen_x <= coords.maxLng) {
                    
                
                    if (google.maps.geometry.poly.isLocationOnEdge(new google.maps.LatLng({
                            lat: item.b.cen_y,
                            lng: item.b.cen_x
                        }), hiddenPolyline, 0.01)) {
                        const marker = new google.maps.Marker({
                            position: new google.maps.LatLng({
                                lat: item.b.cen_y,
                                lng: item.b.cen_x
                            }),
                            title: item.n,
                            icon: '/images/fuel_station.png',
                            scaledSize: 0.1
                        });
                        nearestZones.push(marker);
                        nearestCoords.push({
                            lat: item.b.cen_y,
                            lng: item.b.cen_x,
                            title: item.n
                        });
                    }
                }
            });
            */
          
            if (nearestCoords.length === 0) {
                this.props.resetModalWindow('Ближайшие заправки не найдены.', 'Внимание', true);
            } else {
                this.props.onChange({
                    type: 'SAVE_NEAREST_ZONES',
                    markers: nearestZones
                });
                this.props.onChange({
                    type: 'SAVE_NEAREST_COORDS',
                    coords:nearestCoords
                });
                const markerCluster = new MarkerClusterer(this.props.map, nearestZones, {
                    imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
                });
                this.props.onChange({
                    type: 'SAVE_CLUSTER',
                    cluster: markerCluster
                });
                $('.wrap-fuelStations').css('display', 'block');
            }
        }
    }

    showPointOnMap(event) {
        const index = event.currentTarget.sectionRowIndex;
        const nearestPoints = this.props.nearestCoords;
        this.props.map.setCenter(new google.maps.LatLng({
            lat: nearestPoints[index].lat,
            lng: nearestPoints[index].lng
        }));
        this.props.map.setZoom(14);
    }
}

export default FuelStationsController;